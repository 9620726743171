import React, {useEffect} from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {
    getObjectsByRegEx,
    save,
    deleteDoc,
    getObjectByDoc,
    PHYSICAL_CHARACTERIZATION_CBD,
} from '../../../api/Compounds';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import { renderFields } from "../../../utils/pair_entry";
import FormEditModal from "../../common/FormEditModal";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import {isNotEmpty} from "../../../utils/string";
import {useRoles} from "../../../App.Context";
import {Physical_characterization_CBD} from "./types";


const _PhysicalCharacterizationCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data , searchBy, searchByDoc, isActive}) => {

    const UUID = '_PhysicalCharacterizationCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACK

    const saveData = async ( data: any) => {
        save(PHYSICAL_CHARACTERIZATION_CBD, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const deleteData = async (data: any) => {
        deleteDoc(PHYSICAL_CHARACTERIZATION_CBD, data).then((res) => {
            setData({});
            setIsShow(false);
        }).catch(setError);
    }


    const undo = async (undoData:any) => {
        Object.assign(data, undoData);
        save(PHYSICAL_CHARACTERIZATION_CBD, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const createEvent = (event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    };

    // ----- EVENTS

    const searchEvent = (event: { detail: string }) => {
        if (isActive) {
            searchBy?.(getObjectsByRegEx,PHYSICAL_CHARACTERIZATION_CBD,  'SUBSTANCE,INCHIKEY,SUPPLIER', event.detail);
        }
    }
    const searchByCompoundEvent = (event: { detail: any }) => {
        if (isActive) {
            searchByDoc?.(getObjectByDoc,PHYSICAL_CHARACTERIZATION_CBD, event.detail, true, 'SUBSTANCE');
        }
    }


    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_PHYSICAL_PROPERTIES_CBD, searchByCompoundEvent, UUID);

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_PHYSICAL_PROPERTIES_CBD, UUID);

        };
    }, [isActive]);

    // RENDERS --------------

    return(
        <p>
            {renderFields(Physical_characterization_CBD, data)}

            { !hasReadOnlyRole && (
                <>
                    <FormEditModal
                        save={saveData}
                        DATATYPE={PHYSICAL_CHARACTERIZATION_CBD}
                        data={data}
                        schema={Physical_characterization_CBD}/>

                    <DisplayDeleteModal DATATYPE={PHYSICAL_CHARACTERIZATION_CBD}
                                        deleteDoc={deleteData}
                                        data={data}
                                        schema={Physical_characterization_CBD}/>

                </>
            )}

            <AuditModal id={data._id} undo={undo}/>


        </p>
    );

};


const PhysicalCharacterizationCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width,eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PhysicalCharacterizationCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(PhysicalCharacterizationCard);
