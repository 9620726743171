import React, {useEffect} from 'react';
import Events from '../../../events';
import {EventsType} from '../../../events-types';
import {PRODUCT_ANALYSIS_VALUES, deleteDoc, getObjectByDoc, getObjectsByRegEx, save} from '../../../api/Products';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {isNotEmpty, isValidIchiKey} from "../../../utils/string";
import {renderFields} from "../../../utils/pair_entry";
import FormEditModal from '../../common/FormEditModal';
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import {useRoles} from "../../../App.Context";
import {ProductAnalysisValue} from "./types";

const _ProductAnalisysValueCard: React.FC<iBaseInnerCardProps> = ({ setIsShow,
                                                          setError,
                                                          setData,
                                                          data ,
                                                          isActive,
                                                          setTitle,
                                                          searchBy,
                                                          searchByDoc}) => {

    const UUID = '_ProductAnalisysValueCard'
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACK

    const saveData = (data: any) => {
        save(PRODUCT_ANALYSIS_VALUES, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const deleteData = (data: any) => {
        deleteDoc(PRODUCT_ANALYSIS_VALUES, data).then((res) => {
            setData({});
            setIsShow(false);
        }).catch(setError);
    }

    const undo = (undoData:any) => {
        Object.assign(data, undoData);
        save(PRODUCT_ANALYSIS_VALUES, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const createEvent = (event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    };

    // SEARCH functions ----------------------


    const searchEvent = (event: { detail: string }) => {
        if (!isActive) return;
        searchByTextEvent(event);
    };

    const searchByTextEvent = async (event: { detail: string }) => {

        if (!isActive) return;

        searchBy?.(getObjectsByRegEx, PRODUCT_ANALYSIS_VALUES,
            'TEST_METHOD_CODE,REPORT_PROJECT_ID,PRODUCT,SAMPLE_ID', event.detail);

    }

    const searchByObjectEvent = (event: { detail: any }) => {

        if (!isActive) return;

        searchByDoc?.(getObjectByDoc, PRODUCT_ANALYSIS_VALUES, event.detail, true, 'PRODUCT ANALYSIS', false );

    }

    // EVENTS --------------

    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_VALUE, searchByObjectEvent, UUID);

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_VALUE, UUID);

        };
    }, [isActive]);


    // RENDER ----------------



    return(
        <>
            {renderFields(ProductAnalysisValue, data)}

            { !hasReadOnlyRole && (
                <>
                    <FormEditModal DATATYPE={PRODUCT_ANALYSIS_VALUES}
                                   save={saveData}
                                   data={data}
                                   schema={ProductAnalysisValue}/>

                    <DisplayDeleteModal DATATYPE={PRODUCT_ANALYSIS_VALUES}
                                        deleteDoc={deleteData}
                                        data={data}
                                        schema={ProductAnalysisValue}/>
                </>
            )}

            <AuditModal id={data._id} undo={undo}/>

        </>
    );

};


const ProductAnalisysValueCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent
        cardComponent={_ProductAnalisysValueCard}
        header={header}
        width={width}
        eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(ProductAnalisysValueCard);


