
export interface iStrain {
    VARIETY_NAME: string;
    LAB_ID:string;
    ANONYMOUS_PRODUCER: string;
    MAIN_PURPOSE: string;
    COUNTRY: string;
    COUNTRY_REGION: string;
    PRODUCT_CATEGORY: string;
    STRAIN_CATEGORY: string;
    STRAIN_POPULARITY: string;
    CHEMOTYPE: string;
    CHEMOTYPE_RATIO: string;
    TOTAL_THC: string;
    TOTAL_CBD: string;
    TOTAL_CBG: string;
    TOTAL_CBC: string;
    TOTAL_CBN: string;
    TOTAL_THCV: string;
    TOTAL_TERPENES: string;
    TERPENES_VAR: string;
    DIOECIOUS_OR_MONOECIOUS: string;
    DESCRIPTORS_AND_GROWTH: string;
    PROPERTIES: string;
    SOURCE: string;
    NOTES: string;
    MAINTAINER_N_LIST: string;
    MAINTAINER: string;
    VARIETY_STATUS: string;
    REGISTRATION_DATE: string;
    GMO: string;
    PMA: string;
    VARIETY_ANOTHER_NAME: string;
    HYBRID: string;
    STRAIN_ID: string;
}

export const Strain: iStrain = {
    VARIETY_NAME: '',
    LAB_ID: '',
    ANONYMOUS_PRODUCER: '',
    MAIN_PURPOSE: '',
    COUNTRY: '',
    COUNTRY_REGION: '',
    PRODUCT_CATEGORY: '',
    STRAIN_CATEGORY: '',
    STRAIN_POPULARITY: '',
    CHEMOTYPE: '',
    CHEMOTYPE_RATIO: '',
    TOTAL_THC: '',
    TOTAL_CBD: '',
    TOTAL_CBG: '',
    TOTAL_CBC: '',
    TOTAL_CBN: '',
    TOTAL_THCV: '',
    TOTAL_TERPENES: '',
    TERPENES_VAR: '',
    DIOECIOUS_OR_MONOECIOUS: '',
    DESCRIPTORS_AND_GROWTH: '',
    PROPERTIES: '',
    SOURCE: '',
    NOTES: '',
    MAINTAINER_N_LIST: '',
    MAINTAINER: '',
    VARIETY_STATUS: '',
    REGISTRATION_DATE: '',
    GMO: '',
    PMA: '',
    VARIETY_ANOTHER_NAME: '',
    HYBRID: '',
    STRAIN_ID: ''
}

export interface iConcCompoundsByStrains {

    INCHIKEY: string;
    //  STRAIN_ID: string;
    CONCENTRATION: string;

}

export const ConcCompoundsByStrains: iConcCompoundsByStrains = {

    INCHIKEY: '',
    //  STRAIN_ID: '',
    CONCENTRATION: ''

}
