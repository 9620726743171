import React, {useEffect} from 'react';
import Events from '../../../events';
import {EventsType} from '../../../events-types';
import {
    PRODUCT_DEGRADATION,
    deleteDoc,
    getObjectByDoc,
    getObjectsByRegEx,
    save,
    getObjectsByFieldOnDegradedCompound
} from '../../../api/Products';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {isNotEmpty} from "../../../utils/string";
import {renderFields} from "../../../utils/pair_entry";
import FormEditModal from '../../common/FormEditModal';
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import {useRoles} from "../../../App.Context";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Button} from "@mui/material";
import {
    iProductDegradation,
    ProductDegradation,
    ProductDegradationAppliedFilters,
    ProductDegradationBase,
    ProductDegradationConditionsSettings,
    ProductDegradationOtherSettings,
    ProductDegradationStructureFilters,
    ProductDegradationSystemSettings,
    ProductDegradationTransformationsSettings
} from "./types";

const _ProductDegradationCard: React.FC<iBaseInnerCardProps> = ({ setIsShow,
                                                          setError,
                                                          setData,
                                                          data ,
                                                          isActive,
                                                          setTitle,
                                                          searchBy,
                                                          searchByDoc}) => {

    const UUID = '_ProductDegradationCard'
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACK

    const saveData = (data: any) => {
        save(PRODUCT_DEGRADATION, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const deleteData = (data: any) => {
        deleteDoc(PRODUCT_DEGRADATION, data).then((res) => {
            setData({});
            setIsShow(false);
        }).catch(setError);
    }

    const undo = (undoData:any) => {
        Object.assign(data, undoData);
        save(PRODUCT_DEGRADATION, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const createEvent = (event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    };

    // SEARCH functions ----------------------


    const searchEvent = (event: { detail: string }) => {
        if (!isActive) return;
        searchByTextEvent(event);
    };


    const searchByTextEvent = async (event: { detail: string }) => {
        if (!isActive) return;
        searchBy?.(getObjectsByRegEx, PRODUCT_DEGRADATION,
            'TYPE,NAME,SETTINGS,DESCRIPTION', event.detail);

    }

    const searchByObjectEvent = (event: { detail: any }) => {
        if (!isActive) return;
        searchByDoc?.(getObjectByDoc, PRODUCT_DEGRADATION, event.detail, true, 'PRODUCT DEGRADATION' );

    }

    const searchByProductDegradationID = async (event: { detail: string}) => {
        if (!isActive) return;

        const data = event.detail.split("#");
        const data_search = { "TYPE" : data[0] ,
                                                        "NAME" : data[1],
                                                        "SETTINGS" : data[2],
                                                        "DATE_CREATED" : data[3]};

        searchByDoc?.(getObjectByDoc, PRODUCT_DEGRADATION, data_search, false, 'PRODUCT DEGRADATION' );
    }

    const searchByInchiKeyEvent = async(event: { detail: string }) => {
        if (!isActive) return;
        searchBy?.(getObjectsByFieldOnDegradedCompound, PRODUCT_DEGRADATION, 'INCHIKEY', event.detail);
    }

    // EVENTS --------------

    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_PRODUCT_DEGRADATION, searchByObjectEvent, UUID);
        Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
        Events.on(EventsType.SEARCH_BY_PRODUCT_DEGRADATION_PRODUCT_ID, searchByProductDegradationID, UUID);

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_DEGRADATION, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_DEGRADATION_PRODUCT_ID, UUID);
        };
    }, [isActive]);


    // RENDER ----------------


    const createProductID = (data: iProductDegradation) => {
        return (data.TYPE + "#" + data.NAME + "#" + data.SETTINGS + "#" + data.DATE_CREATED)
    }


    return(
        <>
            <React.Fragment key="LOAD_DEGRADATION_COMPOUNDS_of_PRODUCT" >
                <Button variant="contained" size="small"
                        style={{ margin: '5px'}}
                        color="secondary"
                        onClick={() => {
                            Events.trigger(EventsType.SEARCH_BY_PRODUCT_DEGRADATION_PRODUCT_ID, createProductID(data));
                        }}>
                    LOAD DEGRADATION COMPOUNDS of PRODUCT
                </Button>
                &nbsp;
            </React.Fragment>
            <br/>

            {renderFields(ProductDegradationBase, data)}
            { !hasReadOnlyRole && (
                <>
                    <FormEditModal DATATYPE={PRODUCT_DEGRADATION}
                                   save={saveData}
                                   data={data}
                                   schema={ProductDegradationBase}/>

                    <DisplayDeleteModal DATATYPE={PRODUCT_DEGRADATION}
                                        deleteDoc={deleteData}
                                        data={data}
                                        schema={ProductDegradation}/>

                </>
            )}

            <AuditModal id={data._id} undo={undo}/>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel-header-settings"
                >
                    Settings Data
                </AccordionSummary>
                <AccordionDetails>
                    {renderFields(ProductDegradationSystemSettings, data)}
                    { !hasReadOnlyRole && (
                        <>
                            <FormEditModal DATATYPE={PRODUCT_DEGRADATION}
                                           save={saveData}
                                           data={data}
                                           schema={ProductDegradationSystemSettings}/> </>
                    )}
                    <hr/>
                    {renderFields(ProductDegradationConditionsSettings, data)}
                    { !hasReadOnlyRole && (
                        <>
                            <FormEditModal DATATYPE={PRODUCT_DEGRADATION}
                                           save={saveData}
                                           data={data}
                                           schema={ProductDegradationConditionsSettings}/> </>
                    )}
                    <hr/>
                    {renderFields(ProductDegradationOtherSettings, data)}
                    { !hasReadOnlyRole && (
                        <>
                            <FormEditModal DATATYPE={PRODUCT_DEGRADATION}
                                           save={saveData}
                                           data={data}
                                           schema={ProductDegradationOtherSettings}/> </>
                    )}
                    <hr/>
                    {renderFields(ProductDegradationAppliedFilters, data)}
                    { !hasReadOnlyRole && (
                        <>
                            <FormEditModal DATATYPE={PRODUCT_DEGRADATION}
                                           save={saveData}
                                           data={data}
                                           schema={ProductDegradationAppliedFilters}/> </>
                    )}
                    <hr/>
                    {renderFields(ProductDegradationTransformationsSettings, data)}
                    { !hasReadOnlyRole && (
                        <>
                            <FormEditModal DATATYPE={PRODUCT_DEGRADATION}
                                           save={saveData}
                                           data={data}
                                           schema={ProductDegradationTransformationsSettings}/> </>
                    )}
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                                   aria-controls="panel2-content"
                                   id="panel-header-filters">
                    Filters Data
                </AccordionSummary>
                <AccordionDetails>
                    {renderFields(ProductDegradationStructureFilters, data)}
                    { !hasReadOnlyRole && (
                        <>
                            <FormEditModal DATATYPE={PRODUCT_DEGRADATION}
                                           save={saveData}
                                           data={data}
                                           schema={ProductDegradationStructureFilters}/>

                            <DisplayDeleteModal DATATYPE={PRODUCT_DEGRADATION}
                                                deleteDoc={deleteData}
                                                data={data}
                                                schema={ProductDegradation}/>
                        </>
                    )}
                </AccordionDetails>
            </Accordion>



        </>
    );

};


const ProductDegradationCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent
        cardComponent={_ProductDegradationCard}
        header={header}
        width={width}
        eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(ProductDegradationCard);


