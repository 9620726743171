import React, {useEffect} from 'react';

import {Box, IconButton} from "@mui/material";
import {FileDownload as FileDownloadIcon, Visibility as VisibilityIcon} from "@mui/icons-material";

import MaterialReactTable from 'material-react-table';

import Events from '../../../events';
import {EventsType} from '../../../events-types';

import {getByFilters, getObjectsByRegEx, PRODUCT_ANALYSIS_VALUES} from '../../../api/Products';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";

import Button from "@mui/material/Button";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {CellEventMapping, createCellsWithEvents, getColumns, getInitialColumnState} from "../../../utils/tables";
import {OnChangeFn} from "@tanstack/table-core/src/types";
import {ColumnFiltersState} from "@tanstack/table-core/src/features/Filters";
import {exportToExcel, exportToExcelSelectedRows} from "../../../utils/export_xlsx";
import {ProductAnalysisValue} from "./types";


const _ProductAnalysisValuesTable: React.FC<iBaseInnerCardProps> = ({ data ,
                                                            setColumnFilters, columnFilters,
                                                            // setTextSearch, textSearch,
                                                            isActive,
                                                            searchListBy, searchListByFilters
                                                        }) => {

    const UUID = '_ProductAnalysisTable'

    const dynamicFields = { COMPOUND_NAME: 'string' };
    const extendedProductAnalysis = { ...ProductAnalysisValue, ...dynamicFields };

    const columnNames = Object.keys(extendedProductAnalysis);
    const columnsToView = ['ANALYSIS_TYPE', 'REPORT_PROJECT_ID', 'PRODUCT', 'COMPOUND_NAME', 'UNIT', 'AVERAGE','STD_DEV' ];

    const eventMapping: CellEventMapping = {
        'ANALYSIS_TYPE': EventsType.SEARCH_BY_PRODUCT_ANALYSIS_TYPE,
        'TEST_METHOD_CODE': EventsType.SEARCH_BY_PRODUCT_ANALYSIS_METHOD_CODE,
        'REPORT_PROJECT_ID': EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT_ID,
        'CLASS': EventsType.SEARCH_BY_PRODUCT_ANALYSIS_CLASS
    };

    const columns = getColumns(extendedProductAnalysis,2, createCellsWithEvents(eventMapping));
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);


    // SEARCH -------------

    function _search(text: string) {
        if (text.length > 0){
            searchListBy?.(getObjectsByRegEx, PRODUCT_ANALYSIS_VALUES,
                'ANALYSIS_TYPE,CLASS,REPORT_PROJECT_ID,PRODUCT,TEST_METHOD_CODE,INCHIKEY', text);
        }
    }

    const searchEvent = (event: { detail: string }) => {
        if (isActive){
            _search(event.detail);
        }
    }

    const searchByInchiKeyEvent = (event: { detail: string }) => {

        if (!isActive) return;
        searchListBy?.(getObjectsByRegEx, PRODUCT_ANALYSIS_VALUES, 'INCHIKEY',  '^' + event.detail + '$'); //remove regex from inchikey search
    }


    const searchWizard = async(event: { detail: any }) => {
        if (isActive && event.detail.component === PRODUCT_ANALYSIS_VALUES){
            searchListByFilters?.(getByFilters, PRODUCT_ANALYSIS_VALUES, event.detail.filters, 'wizard result');
        }
    }

    const searchByReport = async(event: { detail: any }) => {
        if (!isActive) return;
        const _filters = [
            { "property": "ANALYSIS_TYPE", "value": event.detail.ANALYSIS_TYPE, "operator": "==" },
            { "property": "REPORT_PROJECT_ID", "value": event.detail.REPORT_PROJECT_ID, "operator": "==" },
            { "property": "PRODUCT", "value": event.detail.PRODUCT, "operator": "==" }
        ]

        searchListByFilters?.(getByFilters, PRODUCT_ANALYSIS_VALUES, _filters,
            `Values from ${event.detail.ANALYSIS_TYPE} :: ${event.detail.REPORT_PROJECT_ID} :: ${event.detail.PRODUCT} `);
    }

    const searchByClass = async (event: { detail: any }) => {
        if (!isActive) return;
        searchListBy?.(getObjectsByRegEx, PRODUCT_ANALYSIS_VALUES, 'CLASS',  event.detail);
    }

    const searchByReportID = async (event: { detail: any }) => {
        if (!isActive) return;
        searchListBy?.(getObjectsByRegEx, PRODUCT_ANALYSIS_VALUES, 'REPORT_PROJECT_ID',  event.detail);
    }

    const searchByAnalysisType = async (event: { detail: any }) => {
        if (!isActive) return;
        searchListBy?.(getObjectsByRegEx, PRODUCT_ANALYSIS_VALUES, 'ANALYSIS_TYPE',  event.detail);
    }

    // EVENTS ---------------

    useEffect(() => {

        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
        Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);
        Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT, searchByReport, UUID);
        Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_CLASS, searchByClass, UUID);
        Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT_ID, searchByReportID, UUID);
        Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_TYPE, searchByAnalysisType, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_CLASS, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_REPORT_ID, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_TYPE, UUID);
        };
    }, [isActive]);


    // RENDER ----------------------

    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };

    return (

        <MaterialReactTable
            columns={columns}
            data={data?? []}
            enableRowSelection //enable some features
            enableColumnOrdering
            enableGlobalFilter={false} //turn off a feature
            enableDensityToggle={true}
            initialState={{
                columnVisibility: _initial_columns_state,
                density: 'compact',
                columnOrder: [
                    'mrt-row-actions',
                    'mrt-row-select',
                    'ANALYSIS_TYPE',
                    'CLASS',
                    'REPORT_PROJECT_ID',
                    'PRODUCT',
                    'SAMPLE_ID',
                    'TEST_METHOD_CODE',
                    'COMPOUND_NAME',
                    'INCHIKEY',
                    'UNIT',
                    'AVERAGE',
                    'STD_DEV',
                    'LOQ',
                    'LOD',
                    'NUMBER_OF_ITEMS_PER_REPLICATE',
                    'NUMBER_OF_REPLICATES'
                ],
            }}

            /* SAVE FILTERS   */

            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}


            /* ROW ACTION   */

            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                            Events.trigger(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_VALUE, row.original);
                        }}
                    >
                        <VisibilityIcon />
                    </IconButton>

                </Box>
            )}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "Compounds")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "ProductAnalysis")}

                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>

                </Box>
            )}
        />
    );
};

const ProductAnalysisValuesTable: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent
        cardComponent={_ProductAnalysisValuesTable}
        header={header}
        width={width}
        eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(ProductAnalysisValuesTable);
