import { Basket} from '../components/tabs/basket/Basket';
import { getObjectsByRegEx, save, deleteDoc } from '../api/Baskets';
import {getCurrentUserNameAndRoles} from "./Cognito";

class MultiBasket {

    private user: string;
    private baskets: Basket[];

    constructor() {
        this.baskets = [];
        this.user = 'undefined';
        this.initialize();
    }

    async initialize() {
        this.user = (await getCurrentUserNameAndRoles())?.login || 'undefined';
        await this.load();
    }

    async deleteAll() {
        for (const storage of this.baskets.values()) {
            await deleteDoc(storage);
        }
        this.baskets = []
        await this.initialize();
    }

    async getAllBasketsNames() {
        if (this.baskets.length === 0) {
            await this.initialize();
        }
        return this.baskets.map(basketInstance  => basketInstance.name);
    }

    async load() {
        try {
            this.baskets = await getObjectsByRegEx('user', this.user);
        } catch (error) {
            console.error('Error loading baskets:', error);
        }
    }

    async clone(basket: any) {

        if (basket instanceof CustomEvent) {
            basket = basket.detail;
        }

        console.log(basket);
        basket.user = this.user;
        basket.shared = false;

        Object.keys(basket)
            .filter(prop => prop.startsWith('_'))
            .forEach(prop => delete basket[prop]);

        const new_basket = await save(basket);
        this.baskets.push(new_basket);
        return basket;
    }

    async create(basketKey: string) {
        const existingBasket = this.getBasket(basketKey);
        if (existingBasket && existingBasket.name === basketKey) {
            console.warn(`Storage with key "${basketKey}" already exists.`);
            return existingBasket;
        }

        const newBasket = new Basket(basketKey, this.user);
        const new_basket = await save(newBasket);
        this.baskets.push(new_basket);

        return newBasket;
    }

    deleteBasket(basketName: string): void {
        const existingBasket = this.getBasket(basketName);
        if (existingBasket && existingBasket.name === basketName){
            deleteDoc(existingBasket);
            this.baskets.remove(existingBasket);
        }
    }

    rename(oldBasketName: string, newBasketName: string) {
        const existingBasket = this.getBasket(oldBasketName);
        if (existingBasket && existingBasket.name === oldBasketName){
            existingBasket.name = newBasketName;
            save(existingBasket);
        }
    }

    getBasket(basketName: string): Basket {
        const st = this.baskets.find(storage => storage.name === basketName);
        if (st) return st;
        return new Basket('empty', this.user)
    }

}

export default MultiBasket;
