import React, {useEffect} from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {deleteDoc, getObjectsByRegEx, save, MUTAGENICITY, getObjectByDoc} from '../../../api/Compounds';
import { iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {isNotEmpty, isNotUndefined, isValidIchiKey} from "../../../utils/string";
import FormEditModal from "../../common/FormEditModal";
import {renderFields} from "../../../utils/pair_entry";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import {useRoles} from "../../../App.Context";

import {Mutagenicity} from "./types";

const _MutagenicityCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                            setData, data , isActive,
                                                            setTitle, setSubtitle, searchBy, searchByDoc }) => {
    const UUID = '_MutagenicityCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACK
    const saveData = async (data: any) => {
        save(MUTAGENICITY, data).then((res) => {
            setData(res);
        }).catch(setError);
    }


    const deleteData = async (data: any) => {
        deleteDoc(MUTAGENICITY, data).then((res) => {
            setData({});
            setIsShow(false);
        }).catch(setError);
    }


    const undo = async (undoData:any) => {
        Object.assign(data, undoData);
        save(MUTAGENICITY, data).then((res) => {
            setData(res);
        }).catch(setError);
    }

    const createEvent = (event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    };


    // SEARCH ----------------------

    function _search(text: string) {
        if (text.length > 0) {
            if (isValidIchiKey(text)) {
                searchBy?.(getObjectsByRegEx,MUTAGENICITY, 'INCHIKEY', '^' + text + '$');
            } else {
                searchBy?.(getObjectsByRegEx,MUTAGENICITY, 'MODEL_NAME,MODEL_ENDPOINT,PROGRAM_VERSION', text);
            }
        }
    }

    const searchEvent = async (event: { detail: string }) => {
        if (!isActive) return;
        _search(event.detail);

    }

    const searchByCompoundEvent = async (event: { detail: any }) => {
        if (isActive) {
            searchByDoc?.(getObjectByDoc, MUTAGENICITY, event.detail, true, 'COMPOUND_NAME' );
        }
    }

    // ----- EVENTS

    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_INCHIKEY, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_MUTAGENICITY, searchByCompoundEvent, UUID);

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_MUTAGENICITY, UUID);

        };
    }, [isActive]);

    // RENDERS --------------


    return(
        <p>
            {renderFields(Mutagenicity, data)}

            { !hasReadOnlyRole && (
                <>
                    <FormEditModal
                        DATATYPE={MUTAGENICITY}
                        save={saveData}
                        data={data}
                        schema={Mutagenicity}/>

                    <DisplayDeleteModal DATATYPE={MUTAGENICITY}
                                        deleteDoc={deleteData}
                                        data={data}
                                        schema={Mutagenicity}/>
                </>
            )}

            <AuditModal id={data._id} undo={undo}/>
        </p>
    );


};


const MutagenicityCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_MutagenicityCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(MutagenicityCard);

