import React, { useState } from 'react';
import {Button, Tooltip} from "@mui/material";
import FormEditModal from './common/FormEditModal';
import './../css/AddEditButton.css';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


import {
    AROMA,
    CANNABINOIDS,
    COMPOUND,
    MARKET_CBD, PHARMACO_DYNAMICS,
    PHYSICAL_CHARACTERIZATION_CBD,
    REFERENCES,
    save as saveCompounds,
    TERPENES
} from './../api/Compounds';
import {save as saveStrains, STRAINS} from './../api/Strains';
import {
    CBD_PK_INDICES,
    save as saveStudies,
    SC_PK,
    SC_THERAPEUTIC_INDICATIONS,
    STUDIES_CBD,
    STUDIES_METABOLITES
} from './../api/Studies';

import {useMessage} from "./ContextMessage";
import {isNotEmpty} from "../utils/string";
import Events from "../events";
import {EventsType} from "../events-types";
import {Strain as StrainObject} from "./tabs/plants_varieties/types";
import {Aroma, Cannabinoid, Compound, Terpene} from "./tabs/compounds/types";
import {Metabolite, PharmacoDynamics, PK, PK_Indices} from "./tabs/pharmacology/types";
import {TherapeuticIndicator} from "./tabs/./disorders_diseases/types";
import {Market_CBD, Physical_characterization_CBD} from "./tabs/products/types";
import {Reference} from "./tabs/references/types";


const AddNewData = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedDataType, setSelectedDataType] = useState('');
    const [selectedDataSubType, setSelectedDataSubType] = useState('');
    const [dataToSave, setDataToSave] = useState({});
    const { showWarning } = useMessage();

    const toggleMenu = () => {
        setSelectedDataType('');
        setSelectedDataSubType('');
        setMenuOpen(!menuOpen);
    };

    const handleOptionClick = (dataType: string, dataSubType?: string) => {
        setSelectedDataType(dataType);
        setSelectedDataSubType(dataSubType || '');
        setMenuOpen(false);
    };

    const saveData = async (data: any) => {
        try {
            let res;

            switch (selectedDataType) {
                case COMPOUND:
                case CANNABINOIDS:
                case TERPENES:
                case AROMA:
                case REFERENCES:
                case MARKET_CBD:
                case PHYSICAL_CHARACTERIZATION_CBD:
                case PHARMACO_DYNAMICS:
                    res = await saveCompounds(selectedDataType, data);
                    break;
                case STRAINS:
                    res = await saveStrains(selectedDataType, data);
                    break;
                case SC_PK:
                case SC_THERAPEUTIC_INDICATIONS:
                case STUDIES_METABOLITES:
                case CBD_PK_INDICES:
                    res = await saveStudies(selectedDataType, data);
                    break;
                default:
                    throw new Error('Invalid DATATYPE');
            }
            setDataToSave(res);
            setMenuOpen(false);
            Events.trigger(EventsType.RELOAD, {});
        } catch (error) {
            showWarning('Compound value was NOT saved!');
        }
    };

    const getSchemaForDataType = (dataType: string, dataSubType?:string ) => {
        switch (dataType) {
            case COMPOUND:
                return Compound;
            case CANNABINOIDS:
                return Cannabinoid;
            case STUDIES_METABOLITES:
                return Metabolite;
            case MARKET_CBD:
                return Market_CBD;
            case PHYSICAL_CHARACTERIZATION_CBD:
                return Physical_characterization_CBD;
            case PHARMACO_DYNAMICS:
                return PharmacoDynamics;
            case STUDIES_CBD:
                if (dataSubType ===  SC_PK){
                    return PK;
                } else {
                    return TherapeuticIndicator;
                }
            case CBD_PK_INDICES:
                return PK_Indices;
            case REFERENCES:
                return Reference;
            case STRAINS:
                return StrainObject;
            case TERPENES:
                return Terpene;
            case AROMA:
                return Aroma;
            default:
                return {};
        }
    };

    return (
        <div className="add-edit-button-container">
            <Tooltip title="Create/Add new data" placement="top" arrow>
                <Button className="add-edit-button"
                        style={{ height: '32px',  boxShadow:'0px 0px 0px 0px' , marginRight: '5px'}}
                        onClick={toggleMenu}
                        variant="contained"
                        color="primary"
                        aria-label={"Add new DATA"}>
                  <AddCircleOutlineIcon />
                </Button>
            </Tooltip>

            {menuOpen && (
                <div className="menu-options">

                    <div className="menu-option" onClick={() => handleOptionClick(AROMA)}>
                        Aroma
                    </div>
                    <div className="menu-option" onClick={() => handleOptionClick(CANNABINOIDS)}>
                        Cannabinoid
                    </div>
                    <div className="menu-option" onClick={() => handleOptionClick(MARKET_CBD)}>
                        CBD Market
                    </div>
                    <div className="menu-option" onClick={() => handleOptionClick(COMPOUND)}>
                        Compound
                    </div>
                    <div className="menu-option" onClick={() => handleOptionClick(STUDIES_METABOLITES)}>
                        Metabolite
                    </div>
                    <div className="menu-option" onClick={() => handleOptionClick(PHARMACO_DYNAMICS)}>
                        Pharmaco Dynamics
                    </div>
                    <div className="menu-option" onClick={() => handleOptionClick(PHYSICAL_CHARACTERIZATION_CBD)}>
                        Physical Characterization
                    </div>
                    <div className="menu-option" onClick={() => handleOptionClick(STUDIES_CBD, SC_PK)}>
                        PK
                    </div>
                    <div className="menu-option" onClick={() => handleOptionClick(CBD_PK_INDICES)}>
                        PK Indices
                    </div>
                    <div className="menu-option" onClick={() => handleOptionClick(REFERENCES)}>
                        Reference
                    </div>
                    <div className="menu-option" onClick={() => handleOptionClick(TERPENES)}>
                        Terpene
                    </div>
                    <div className="menu-option"
                         onClick={() => handleOptionClick(STUDIES_CBD, SC_THERAPEUTIC_INDICATIONS)}>
                        Therapeutic Indication
                    </div>
                    <div className="menu-option" onClick={() => handleOptionClick(STRAINS)}>
                        Varieties
                    </div>

                </div>
            )}


            {isNotEmpty(selectedDataType) && (
                <FormEditModal
                    defaultOpen={true}
                    DATATYPE={selectedDataType}
                    save={saveData}
                    data={dataToSave}
                    schema={getSchemaForDataType(selectedDataType, selectedDataSubType)}
                    />
            )}


        </div>
    );
};

export default AddNewData;
