export interface iReference {
    REFERENCE_ID: string;
    REFERENCE_YEAR: string;
    REFERENCE_DOI: string;
    REFERENCE_PMID: string;
    REFERENCE_NCTID: string;
    REFERENCE_TITLE: string;
    REFERENCE_VOLUME: string;
    REFERENCE_PAGES: string;
    REFERENCE_JOURNAL: string;
    REFERENCE_TITLE_JOURNAL: string;
    REFERENCE_DOCUMENT_TYPE: string;
    REFERENCE_AUTHORS: string;
    REFERENCE_SENTENCE: string;
    REFERENCE_APPROVAL_DOCUMENT_SOURCE: string;
    REFERENCE_GRANTID: string;
}

export const Reference: iReference = {
    REFERENCE_ID: '',
    REFERENCE_YEAR: '',
    REFERENCE_DOI: '',
    REFERENCE_PMID: '',
    REFERENCE_NCTID: '',
    REFERENCE_TITLE: '',
    REFERENCE_VOLUME: '',
    REFERENCE_PAGES: '',
    REFERENCE_JOURNAL: '',
    REFERENCE_TITLE_JOURNAL: '',
    REFERENCE_DOCUMENT_TYPE: '',
    REFERENCE_AUTHORS: '',
    REFERENCE_SENTENCE: '',
    REFERENCE_APPROVAL_DOCUMENT_SOURCE: '',
    REFERENCE_GRANTID: ''
}
