//
// Therapeuric Indicator
//
export interface iTherapIndicator {
    PRODUCT: string;
    LINK: string;
    TITLE: string;
    AUTHORS: string;
    YEAR: string;
    TRIAL_TYPE: string;
    DOSE: string;
    TREATMENT_LENGTH: string;
    FORMULATION: string;
    DELIVERY: string;
    CONDITION: string;
    NUMBER_OF_PATIENTS: string;
    MALE_FEMALE: string;
    AGE: string;
    MAIN_RESULT: string;
    SECONDARY_FINDINGS: string;
    PLASMA_LEVELS: string;
    WITHDRAWAL_RATES: string;
    OTHER_COMMENTS: string;
    STUDY_TYPE: string;
    GENDER_SPLIT: string;
    ANY_OTHER_COMMENTS: string;
}

export const TherapeuticIndicator: iTherapIndicator = {
    PRODUCT: '',
    LINK: '',
    TITLE: '',
    AUTHORS: '',
    YEAR: '',
    TRIAL_TYPE: '',
    DOSE: '',
    TREATMENT_LENGTH: '',
    FORMULATION: '',
    DELIVERY: '',
    CONDITION: '',
    NUMBER_OF_PATIENTS: '',
    MALE_FEMALE: '',
    AGE: '',
    MAIN_RESULT: '',
    SECONDARY_FINDINGS: '',
    PLASMA_LEVELS: '',
    WITHDRAWAL_RATES: '',
    OTHER_COMMENTS: '',
    STUDY_TYPE: '',
    GENDER_SPLIT: '',
    ANY_OTHER_COMMENTS: '',
}

export interface iPBClinicalTrial {
    updatedate: string;
    gid: string;
    srcid: string;
    ctid: string;
    cids: string;
    link: string;
    title: string;
    conditions: string;
    interventions: string;
    phase: string;
    status: string;
    diseaseids: string;
}

export const PBClinicalTrials: iPBClinicalTrial = {
    updatedate: '',
    gid: '',
    srcid: '',
    ctid: '',
    cids: '',
    link: '',
    title: '',
    conditions: '',
    interventions: '',
    phase: '',
    status: '',
    diseaseids: ''
}

export interface iClinicalTrial {
    NCT_ID: string;
    TITLE: string;
    STATUS: string;
    MAIN_SPONSOR: string;
    PHASE: string;
    SUMMARY: string;
    DESCRIPTION: string;
}

export const ClinicalTrial: iClinicalTrial = {
    NCT_ID: '',
    TITLE: '',
    STATUS: '',
    MAIN_SPONSOR: '',
    PHASE: '',
    SUMMARY: '',
    DESCRIPTION: ''
}
