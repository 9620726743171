export interface iCannabinoid {
    COMPOUND_NAME: string;
    INCHIKEY: string;
    SMILES: string;
    FAMILY_TYPE_NAME: string;
    FAMILY_TYPE_CODE: string;
    REFERENCE_LEGEND_ARTICLE: string;
    ABBREVIATION: string;
    ISOLATED: string;
    ISOLATED_FROM: string;
    STRUCTURE_ELUCIDATION: string;
    COMMENT: string;
    REF_ARTICLE: string;
    CBD_ID: string;
    PUBCHEM_CID: string;

}

export const Cannabinoid: iCannabinoid = {
    COMPOUND_NAME: '',
    INCHIKEY: '',
    SMILES: '',
    FAMILY_TYPE_NAME: '',
    FAMILY_TYPE_CODE: '',
    REFERENCE_LEGEND_ARTICLE: '',
    ABBREVIATION: '',
    ISOLATED: '',
    ISOLATED_FROM: '',
    STRUCTURE_ELUCIDATION: '',
    COMMENT: '',
    REF_ARTICLE: '',
    CBD_ID: '',
    PUBCHEM_CID: ''
}

export interface iTerpene {
    TERPENE_TYPE: string;
    TERPENE_SUBTYPE: string;
    INCHIKEY: string;
    AROMA: string;
    ISOLATED_YEAR: string;
    ISOLATED_FROM: string;
    REF_ARTICLE: string;
}

export const Terpene: iTerpene = {
    TERPENE_TYPE: '',
    TERPENE_SUBTYPE: '',
    INCHIKEY: '',
    AROMA: '',
    ISOLATED_YEAR: '',
    ISOLATED_FROM: '',
    REF_ARTICLE: ''
}

export interface iFlavonoid {
    FLAVONOIDS_TYPE: string;
    FLAVONOIDS_SUBTYPE: string;
    INCHIKEY: string;
    AROMA: string;
    ISOLATED_YEAR: string;
    ISOLATED_FROM: string;
    REF_ARTICLE: string;
}

export const Flavonoid: iFlavonoid = {
    FLAVONOIDS_TYPE: '',
    FLAVONOIDS_SUBTYPE: '',
    INCHIKEY: '',
    AROMA: '',
    ISOLATED_YEAR: '',
    ISOLATED_FROM: '',
    REF_ARTICLE: ''
}

export interface iPhenols {
    PHENOLS_TYPE: string;
    PHENOLS_SUBTYPE: string;
    INCHIKEY: string;
    AROMA: string;
    ISOLATED_YEAR: string;
    ISOLATED_FROM: string;
    REF_ARTICLE: string;
}

export const Phenols: iPhenols = {
    PHENOLS_TYPE: '',
    PHENOLS_SUBTYPE: '',
    INCHIKEY: '',
    AROMA: '',
    ISOLATED_YEAR: '',
    ISOLATED_FROM: '',
    REF_ARTICLE: ''
}

export interface iAroma {
    INCHIKEY: string;
    COMPOUND_NAME: string;
    AROMA_TYPE: string;
    CHEMICAL_TYPE: string;
    COMMENT: string;
    PRED_LOGP_XLOGP3: string;
    PRED_LOGP_ACDLABS: string;
    BP: string;
    BP_MEDIAN: string;
    BP_PREDICTED: string;
    HEAT_OF_VAPORIZATION: string;
    PREDICTED_ENTHALPY_VAPORIZATION: string;
    VAPOR_PRESSURE: string;
    PREDICTED_VAPOR_PRESSURE: string;
    ODOR: string;
    ODOR_FROM_FLAVORNET: string;
    LINK: string;
    ODOR_THRESHOLD: string;
    TASTE: string;
}

export const Aroma: iAroma = {
    INCHIKEY: '',
    COMPOUND_NAME: '',
    AROMA_TYPE: '',
    CHEMICAL_TYPE: '',
    COMMENT: '',
    PRED_LOGP_XLOGP3: '',
    PRED_LOGP_ACDLABS: '',
    BP: '',
    BP_MEDIAN: '',
    BP_PREDICTED: '',
    HEAT_OF_VAPORIZATION: '',
    PREDICTED_ENTHALPY_VAPORIZATION: '',
    VAPOR_PRESSURE: '',
    PREDICTED_VAPOR_PRESSURE: '',
    ODOR: '',
    ODOR_FROM_FLAVORNET: '',
    LINK: '',
    ODOR_THRESHOLD: '',
    TASTE: '',
}

export interface iPubchem {
    NAME: string;
    VALUES: string;
    DATA_TYPE: string;
}

export const Pubchem: iPubchem = {
    NAME: '',
    VALUES: '',
    DATA_TYPE: ''
}

export interface iChemSpiderChemicalData {
    id: string;
    name: string;
    molecularFormula: string;
    molecularWeight: string;
}

export const ChemSpiderChemicalData: iChemSpiderChemicalData = {
    id: '',
    name: '',
    molecularFormula: '',
    molecularWeight: '',
}

export interface iCompound {
    // _key, _id, _rev,
    COMPOUND_NAME: string;
    CAS: string;
    INCHIKEY: string;
    SMILES_CANONICAL: string;
    SMILES_ISOMERIC: string;
    SYNONYMS: string;
    CBD_ID: string;
    PUBCHEM_CID: string;

}

export const Compound: iCompound = {
    COMPOUND_NAME: '',
    CAS: '',
    INCHIKEY: '',
    SMILES_CANONICAL: '',
    SMILES_ISOMERIC: '',
    SYNONYMS: '',
    CBD_ID: '',
    PUBCHEM_CID: ''
}

export interface iCannabisDB {
    accession: string;
    average_molecular_weight: string;
    bigg_id: string;
    biocyc_id: string;
    cas_registry_number: string;
    chebi_id: string;
    chemical_formula: string;
    chemspider_id: string;
    creation_date: string;
    description: string;
    drugbank_id: string;
    // predicted_properties: string;
    // experimental_properties: string;
    foodb_id: string;
    inchi: string;
    inchikey: string;
    iupac_name: string;
    kegg_id: string;
    knapsack_id: string;
    metlin_id: string;
    monisotopic_molecular_weight: string;
    name: string;
    pdb_id: string;
    phenol_explorer_compound_id: string;
    pubchem_compound_id: string;
    secondary_accessions: string;
    smiles: string;
    synonyms: string;
    taxonomy: string;
    traditional_iupac: string;
    update_date: string;
    version: string;
    wikipedia_id: string;
}

export const CannabisDB: iCannabisDB = {
    accession: '',
    average_molecular_weight: '',
    bigg_id: '',
    biocyc_id: '',
    cas_registry_number: '',
    chebi_id: '',
    chemical_formula: '',
    chemspider_id: '',
    creation_date: '',
    description: '',
    drugbank_id: '',
    // predicted_properties: '',
    // experimental_properties: '',
    foodb_id: '',
    inchi: '',
    inchikey: '',
    iupac_name: '',
    kegg_id: '',
    knapsack_id: '',
    metlin_id: '',
    monisotopic_molecular_weight: '',
    name: '',
    pdb_id: '',
    phenol_explorer_compound_id: '',
    pubchem_compound_id: '',
    secondary_accessions: '',
    smiles: '',
    synonyms: '',
    taxonomy: '',
    traditional_iupac: '',
    update_date: '',
    version: '',
    wikipedia_id: ''
}
