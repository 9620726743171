export interface iMarket_CBD {
    DRUG_ID: string;
    DRUG_NAME: string;
    ACTIVE_INGREDIENT: string;
    THERAPEUTICAL_INDICATION: string;
}

export const Market_CBD: iMarket_CBD = {
    DRUG_ID: '',
    DRUG_NAME: '',
    ACTIVE_INGREDIENT: '',
    THERAPEUTICAL_INDICATION: ''
}

export interface iPhysical_characterization_CBD {
    SUPPLIER: string;
    SUBSTANCE: string;
    INCHIKEY: string;
    PRODUCT_RM_NUMBER: string;
    LOT_NUMBER: string;
    SSA_BET_ANALYSIS_M2_G: string;
    PARTICLE_SIZE_DISTRIBUTION_D10: string;
    PARTICLE_SIZE_DISTRIBUTION_D50: string;
    PARTICLE_SIZE_DISTRIBUTION_D90: string;
    DOMINANT_PARTICLE_SHAPE: string;
    MELTING_POINT_APPARATUS: string;
    MELTING_POINT_DSC: string;
    TG: string;
    CRYSTALLINITY: string;
}

export const Physical_characterization_CBD: iPhysical_characterization_CBD = {
    SUPPLIER: '',
    SUBSTANCE: '',
    INCHIKEY: '',
    PRODUCT_RM_NUMBER: '',
    LOT_NUMBER: '',
    SSA_BET_ANALYSIS_M2_G: '',
    PARTICLE_SIZE_DISTRIBUTION_D10: '',
    PARTICLE_SIZE_DISTRIBUTION_D50: '',
    PARTICLE_SIZE_DISTRIBUTION_D90: '',
    DOMINANT_PARTICLE_SHAPE: '',
    MELTING_POINT_APPARATUS: '',
    MELTING_POINT_DSC: '',
    TG: '',
    CRYSTALLINITY: ''
}

export interface iProductsAnalysisMethod {
    CLASS: string;
    METHOD_DESCRIPTION: string;
    TEST_METHOD_CODE: string;
    MATRIX_CODE: string;

}

export const ProductAnalysisMethod: iProductsAnalysisMethod = {
    CLASS: '',
    METHOD_DESCRIPTION: '',
    TEST_METHOD_CODE: '',
    MATRIX_CODE: '',
}

export interface iProductAnalysisReport {
    ANALYSIS_TYPE: string;
    REPORT_SOURCE: string;
    REPORT_PROJECT_ID: string;
    DATE: string;
    MATRIX_CODE: string;
    SAMPLE_PREPARATION: string;
    PRODUCT: string;
    SAMPLE_ID: string;
    PARAMETERS_OF_SMOKE_OR_AEROSOL_GENERATION: string;
    HEATING_DEVICE: string;
}

export const ProductAnalysisReport: iProductAnalysisReport = {
    ANALYSIS_TYPE: '',
    REPORT_SOURCE: '',
    REPORT_PROJECT_ID: '',
    DATE: '',
    MATRIX_CODE: '',
    SAMPLE_PREPARATION: '',
    PRODUCT: '',
    SAMPLE_ID: '',
    PARAMETERS_OF_SMOKE_OR_AEROSOL_GENERATION: '',
    HEATING_DEVICE: '',
}

export interface iProductAnalysisValue {
    ANALYSIS_TYPE: string;
    TEST_METHOD_CODE: string;
    CLASS: string;
    REPORT_PROJECT_ID: string;
    PRODUCT: string;
    INCHIKEY: string;
    UNIT: string;
    AVERAGE: string;
    STD_DEV: string;
    LOD: string;
    LOQ: string;
    NUMBER_OF_REPLICATES: string;
    NUMBER_OF_ITEMS_PER_REPLICATE: string;
}

export const ProductAnalysisValue: iProductAnalysisValue = {
    ANALYSIS_TYPE: '',
    TEST_METHOD_CODE: '',
    CLASS: '',
    REPORT_PROJECT_ID: '',
    PRODUCT: '',
    INCHIKEY: '',
    UNIT: '',
    AVERAGE: '',
    STD_DEV: '',
    LOQ: '',
    LOD: '',
    NUMBER_OF_ITEMS_PER_REPLICATE: '',
    NUMBER_OF_REPLICATES: ''
}
