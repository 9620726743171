import React, {useCallback, useContext, useEffect, useState} from 'react';
import BasketTableCard from './BasketTableCard';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Events from "../../../events";
import {EventsType} from "../../../events-types";
import {BasketsContext} from "../../../App.Context";
import SharedBasketsModal from "./SharedBaskets";

const AllBaskets: React.FC = () => {
    const multiStorage = useContext(BasketsContext);

    const UUID = '_AllBasketsCard';
    const [basketsName, setBasketsName] = useState('');
    const [allNames4Baskets, setAllNames4Baskets] = useState<string[]>([]);

    const handleCreateBasket = async () => {
        if (basketsName) {
            await multiStorage.create(basketsName);
            setBasketsName('');
            setAllNames4Baskets(await multiStorage.getAllBasketsNames());
        }
    };

    const handleCloneBasket = async (basket: any) => {
        await multiStorage.clone(basket);
        setAllNames4Baskets(await multiStorage.getAllBasketsNames());
    }

    const handleDeleteBasket = useCallback(async (event: { detail: any }) => {
        multiStorage.deleteBasket(event.detail);
        setAllNames4Baskets(await multiStorage.getAllBasketsNames());
    }, [multiStorage]);

    const handleDeleteAll = () => {
        const confirmation = window.confirm('Are you sure you want to delete all storages?');
        if (confirmation) {
            multiStorage.deleteAll();
            setAllNames4Baskets([]);
        }
    };

    const handleRenameBasket = useCallback(async (event: { detail: any }) => {
        const oldBasketName = event.detail.oldBasketName;
        const newBasketName = event.detail.newBasketName;
        multiStorage.rename(oldBasketName, newBasketName);
        setAllNames4Baskets(await multiStorage.getAllBasketsNames());
    }, [multiStorage]);

    useEffect(() => {

        const fetchData = async () => {
            const st_names = await multiStorage.getAllBasketsNames();
            setAllNames4Baskets(st_names);
        };

        fetchData();

        Events.on(EventsType.BUCKET_CLONE, handleCloneBasket, UUID);
        Events.on(EventsType.BUCKET_TO_DELETE, handleDeleteBasket, UUID);
        Events.on(EventsType.BUCKET_RENAME, handleRenameBasket, UUID);

        return () => {
            Events.off(EventsType.BUCKET_CLONE, UUID);
            Events.off(EventsType.BUCKET_TO_DELETE, UUID);
            Events.off(EventsType.BUCKET_RENAME, UUID);
        };

    }, [multiStorage, handleDeleteBasket, handleRenameBasket]);

    return (
        <div>
            <div>
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' ,  alignItems: 'center', justifyContent: 'flex-end',}}
                >
                    <label htmlFor="storageKey"><b>Basket Name:</b></label>
                    <input
                        type="text"
                        id="storageKey"
                        value={basketsName}
                        size={50}
                        onClick={() => setBasketsName('')}
                        onChange={(e) => setBasketsName(e.target.value)}
                    />
                <Button  color="primary"  variant="contained" onClick={handleCreateBasket}  >Create Storage</Button> &nbsp;
                <SharedBasketsModal></SharedBasketsModal> &nbsp;
                <Button  color="secondary"  variant="contained"  onClick={handleDeleteAll}  >
                    <DeleteIcon style={{ marginRight: '0.5rem' }} />
                    Delete All Storages</Button>
                </Box>
            </div>

            {allNames4Baskets.map((key:string) => (
                <div key={key}>
                    <BasketTableCard
                        header=''
                        width={'100%'}
                        showContent={false}
                        showBasket={false}
                        storageValue={key}
                    />
                </div>
            ))}
        </div>
    );
};

export default AllBaskets;
