export interface iMetabolites {
    INCHIKEY: string;
    COMPOUND_NAME: string;
    PUBCHEM_CID: string;
    SPECIES: string;
    BIOLOGICAL_SAMPLE: string;
    DOSE: string;
    DOSING_REGIMEN: string;
    ROUTE: string;
    CONCENTRATION_LEVEL: string;
    DETAILS: string;
    COMMENT: string;
    REFERENCE_ID: string;
}

export const Metabolite: iMetabolites = {
    INCHIKEY: '',
    COMPOUND_NAME: '',
    PUBCHEM_CID: '',
    SPECIES: '',
    BIOLOGICAL_SAMPLE: '',
    DOSE: '',
    DOSING_REGIMEN: '',
    ROUTE: '',
    CONCENTRATION_LEVEL: '',
    DETAILS: '',
    COMMENT: '',
    REFERENCE_ID: '',
}

export interface iPharmacoDynamics {
    INCHIKEY: string;
    COMPOUND_NAME: string;
    SMILES_CANONICAL: string;
    SMILES_ISOMERIC: string;
    CAS: string;
    MEASURE_TYPE: string;
    MEASURE_RELATION: string;
    MEASURE_VALUE: string;
    MEASURE_UNITS: string;
    P_VALUE: string;
    DATA_VALIDITY_COMMENT: string;
    COMMENT: string;
    ASSAY_DESCRIPTION: string;
    ASSAY_ORGANISM: string;
    ASSAY_CELL_TYPE: string;
    ASSAY_SUBCELL_FRACTION: string;
    ASSAY_TISSUE: string;
    ASSAY_TYPE: string;
    CONCENTRATION_TEST_COMPOUND: string;
    UNITS_CONCENTRATION_TEST_COMPOUND: string;
    LIGAND_OR_SUBSTRATE: string;
    TARGET_NAME: string;
    TARGET_ORGANISM: string;
    TARGET_TYPE: string;
    PROPERTIES: string;
    ACTION_TYPE: string;
    SOURCE_DESCRIPTION: string;
    REFERENCE_PMID: string;
    REFERENCE_ID: string;
    REFERENCE_TITLE: string;
    REFERENCE_AUTHORS: string;
    REFERENCE_JOURNAL: string;
    REFERENCE_DOI: string;
}

export const PharmacoDynamics: iPharmacoDynamics = {
    INCHIKEY: '',
    COMPOUND_NAME: '',
    SMILES_CANONICAL: '',
    SMILES_ISOMERIC: '',
    CAS: '',
    MEASURE_TYPE: '',
    MEASURE_RELATION: '',
    MEASURE_VALUE: '',
    MEASURE_UNITS: '',
    P_VALUE: '',
    DATA_VALIDITY_COMMENT: '',
    COMMENT: '',
    ASSAY_DESCRIPTION: '',
    ASSAY_ORGANISM: '',
    ASSAY_CELL_TYPE: '',
    ASSAY_SUBCELL_FRACTION: '',
    ASSAY_TISSUE: '',
    ASSAY_TYPE: '',
    CONCENTRATION_TEST_COMPOUND: '',
    UNITS_CONCENTRATION_TEST_COMPOUND: '',
    LIGAND_OR_SUBSTRATE: '',
    TARGET_NAME: '',
    TARGET_ORGANISM: '',
    TARGET_TYPE: '',
    PROPERTIES: '',
    ACTION_TYPE: '',
    SOURCE_DESCRIPTION: '',
    REFERENCE_PMID: '',
    REFERENCE_ID: '',
    REFERENCE_TITLE: '',
    REFERENCE_AUTHORS: '',
    REFERENCE_JOURNAL: '',
    REFERENCE_DOI: ''
}

export interface iPK {
    LINK: string;
    TITLE: string;
    AUTHORS: string;
    YEAR: string;
    BRIEF_DESCRIPTION: string;
    SUMMARY: string;
    PRODUCT: string;
    DOSE: string;
    FORMULATION: string;
    DELIVERY: string;
    VOLUNTEERS_OR_PATIENTS: string;
    NUMBER_OF_PATIENTS: string;
    MALE_FEMALE: string;
    AGE: string;
    TMAX_CBD: string;
    CMAX_CBD: string;
    HALF_LIFE_CBD: string;
    AUC: string;
    ELIMINATION_RATE: string;
    CLEARANCE: string;
    VOLUME_OF_DISTRIBUTION: string;
    BIOAVAILABILITY: string;
    ANY_OTHER_TISSUES_MEASURED: string;
    URINARY_LEVELS: string;
    METABOLITES_MEASURED: string;
    SIDE_EFFECTS: string;
    OTHER_COMMENTS: string;
    STUDY_TYPE: string;
    SPECIES: string;
    TMAX: string;
    CMAX: string;
    HALF_LIFE: string;
    OTHER_TISSUES_MEASURED: string;
    DIFFERENCES_IN_PK: string;
}

export const PK: iPK = {
    LINK: '',
    TITLE: '',
    AUTHORS: '',
    YEAR: '',
    BRIEF_DESCRIPTION: '',
    SUMMARY: '',
    PRODUCT: '',
    DOSE: '',
    FORMULATION: '',
    DELIVERY: '',
    VOLUNTEERS_OR_PATIENTS: '',
    NUMBER_OF_PATIENTS: '',
    MALE_FEMALE: '',
    AGE: '',
    TMAX_CBD: '',
    CMAX_CBD: '',
    HALF_LIFE_CBD: '',
    AUC: '',
    ELIMINATION_RATE: '',
    CLEARANCE: '',
    VOLUME_OF_DISTRIBUTION: '',
    BIOAVAILABILITY: '',
    ANY_OTHER_TISSUES_MEASURED: '',
    URINARY_LEVELS: '',
    METABOLITES_MEASURED: '',
    SIDE_EFFECTS: '',
    OTHER_COMMENTS: '',
    STUDY_TYPE: '',
    SPECIES: '',
    TMAX: '',
    CMAX: '',
    HALF_LIFE: '',
    OTHER_TISSUES_MEASURED: '',
    DIFFERENCES_IN_PK: '',
}

export interface iPK_Indices {
    LINK: string;
    TITLE: string;
    AUTHORS: string;
    YEAR: string;
    REFERENCE: string;
    DOI: string;
    PMID: string;
    SPECIES: string;
    ROUTE: string;
    ROUTES: string;
    DOSE_MG_KG: string;
    BODYWEIGHT_KG: string;
    SUBSPECIES: string;
    DOSE_MG: string;
    PRODUCT: string;
    FORMULATION: string;
    FORMULATION_CATEGORY: string;
    TMAX_H: string;
    CMAX_NG_ML: string;
    HALF_LIFE_H: string;
    AUC_NG_H_ML: string;
    AUCT: string;
    COMMENTS: string;
    CMAX_DOSEN: string;
    AUC_DOSEN: string;
    CMAX_DOSE: string;
    AUC_DOSE: string;
    CMAX_TMAX: string;
    BIOAVAILABILITY: string;
}

export const PK_Indices: iPK_Indices = {
    LINK: '',
    TITLE: '',
    AUTHORS: '',
    YEAR: '',
    REFERENCE: '',
    DOI: '',
    PMID: '',
    SPECIES: '',
    ROUTE: '',
    ROUTES: '',
    DOSE_MG_KG: '',
    BODYWEIGHT_KG: '',
    SUBSPECIES: '',
    DOSE_MG: '',
    PRODUCT: '',
    FORMULATION: '',
    FORMULATION_CATEGORY: '',
    TMAX_H: '',
    CMAX_NG_ML: '',
    HALF_LIFE_H: '',
    AUC_NG_H_ML: '',
    AUCT: '',
    COMMENTS: '',
    CMAX_DOSEN: '',
    AUC_DOSEN: '',
    CMAX_DOSE: '',
    AUC_DOSE: '',
    CMAX_TMAX: '',
    BIOAVAILABILITY: ''
}
