export interface iMutagenicity {
    "MODEL_NAME": string;
    "MODEL_VERSION": string;
    "PROGRAM_VERSION": string;
    "REPORT_DATE": string;
    "PREDICTION_DATE": string;
    "MODEL_ENDPOINT": string;
    "SARAH_RESULT": string;
    "CONFIDENCE": string;
    "MOLWEIGHT": string;
    "LOGP": string;
    "LOGKP": string;
    "EXACT_MASS": string;
    "EQUIVOCAL_LEVEL": string;
    "REASONING_TYPE": string;
    "SENSITIVITY_LEVEL": string;
    "CERTIFIED_MODEL": string;
    "INCHIKEY": string;
    "POSITIVE_HYPOTHESIS_COUNT": string;
    "NEGATIVE_HYPOTHESIS_COUNT": string;
    "POSITIVE_HYPOTHESIS_COUNT_OVERRULED": string;
    "NEGATIVE_HYPOTHESIS_COUNT_OVERRULED": string;
    "TOTAL_HYPOTHESIS_COUNT": string;
}

export const Mutagenicity: iMutagenicity = {
    CERTIFIED_MODEL: "",
    CONFIDENCE: "",
    EQUIVOCAL_LEVEL: "",
    EXACT_MASS: "",
    INCHIKEY: "",
    LOGKP: "",
    LOGP: "",
    MODEL_ENDPOINT: "",
    MODEL_NAME: "",
    MODEL_VERSION: "",
    MOLWEIGHT: "",
    NEGATIVE_HYPOTHESIS_COUNT: "",
    NEGATIVE_HYPOTHESIS_COUNT_OVERRULED: "",
    POSITIVE_HYPOTHESIS_COUNT: "",
    POSITIVE_HYPOTHESIS_COUNT_OVERRULED: "",
    PREDICTION_DATE: "",
    PROGRAM_VERSION: "",
    REASONING_TYPE: "",
    REPORT_DATE: "",
    SARAH_RESULT: "",
    SENSITIVITY_LEVEL: "",
    TOTAL_HYPOTHESIS_COUNT: ""

}
