/**
 * Component Card wrapper
 * it allows to have the same features for all Specialized Card Components
 */

import React, { useEffect, useMemo, useState, useCallback  } from "react";
import Card from "react-bootstrap/Card";
import {iBaseCardWithComponentProps} from "../types";
import {MRT_ColumnFiltersState} from "material-react-table";
import {generateUUID, getOnlyDatabaseIDS, isEmpty, isNotEmpty, isNotUndefined, isUndefined} from "../../utils/string";
import {Alert, IconButton} from "@mui/material";
import Events from "../../events";
import {EventsType} from "../../events-types";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import StorageIcon from "./StorageIcon";
import {Clear} from "@mui/icons-material";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

export const CardWithBaseComponent: React.FC<iBaseCardWithComponentProps> = ({ cardComponent: CardComponent
                                                                                , header
                                                                                , width = '100%'
                                                                                , showContent = false
                                                                                , showBasket = true
                                                                                , storageValue
                                                                                , eventGroupToTrigger = null
                                                                            }) => {
    const $log: Console = console;

    const [isActive, setIsActive] = React.useState(true);
    const [isShow, setIsShow] = React.useState(false);
    const [isContentShow, setIsContentShow] = React.useState(showContent);

    const [title, setTitle]: [string, (text_title: string) => void] = React.useState("");
    const [subtitle, setSubtitle] = React.useState("");

    const [data, wrappedSetData] = React.useState<any[]>([]);
    const [error, wrappedSetError]: [string, (error: string) => void] = React.useState("");

    // const [textSearch, setTextSearch]: [string, (text_search: string) => void] = React.useState("");

    const [id, setId]: [string, (id: string) => void] = React.useState(generateUUID());
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

    const setData = useCallback((newData: any[]) => {
        setId(generateUUID());
        wrappedSetError('');
        setIsContentShow(true);
        if (eventGroupToTrigger != null && isNotEmpty(newData)) {
            Events.trigger(EventsType.HAS_NEW_DATA, eventGroupToTrigger);
        }
        setColumnFilters([]);
        wrappedSetData(newData);
    }, []);


    const setError = (error: any) => {
        $log.error(error);
        setIsContentShow(false);
        if (error.message) wrappedSetError(error.message);
        else wrappedSetError(error);
    }

    const switchContentShowHide = () => {
        setIsContentShow(!isContentShow);
    };

    /**
     * Function used by TableCards
     * @param func - function coming from the Object (i.ex. getCompound )
     * @param collection - name of the collection associated to the Object search
     * @param key
     * @param value
     * @TODO: Analyze if it's worthy to split BaseCard into a TableCard and BaseCard
     */
    function searchListBy(func: (collection: string, key: string, value: string) => Promise<any[]>
                              , collection:string, key:string, value:string)
    {
        if(value.length > 0) {
            setIsShow(false);
            func(collection, key, value)
                .then(response => {
                    if (isNotUndefined(response) && response.length > 1) {
                        setTitle(value);
                        setData(response);
                        setIsShow(true);
                    }
                }).catch(error => {
                setError(error);
                setIsShow(true)
            });
        }
     }

    /**
     * Function used by TableCards in order to retrieve data from several filters (used in Wizard Search)
     * @param func
     * @param collection
     * @param filters
     * @param title
     */
    function searchListByFilters(func: (collection: string, filters: any[]) => Promise<any[]>
                            ,collection: string, filters: any[], title: string)
    {
            setIsShow(false);
            const clean_filter = filters.filter(obj => obj.property !== "").filter(obj => obj.value !== "")
            func(collection, clean_filter)
                .then(response => {
                    if (isNotUndefined(response)) {
                        setTitle(title);
                        setData(response);
                        setIsShow(true);
                    }
                }).catch(error => {
                setError(error);
                setIsShow(true)
            });

    }

    /**
     * Function used in Simple Cards (one Object)
     * @param func - function coming from the Object (i.ex. getCompound )
     * @param collection - name of the collection associated to the Object search
     * @param key
     * @param value
     */
    function searchBy(func: (collection: string, key: string, value: string) => Promise<any[]>
        , collection:string, key:string, value:string) : Promise<any> {
        return new Promise((resolve, reject) => {
            if (data && (data as Record<string, any>)[key] !== value && value.length > 0 ){
                setIsShow(false);
                func(collection, key, value)
                    .then(response => {
                        if (isNotUndefined(response) && response.length === 1) {
                            setData(response[0]);
                            setIsShow(true);
                            setTitle(value.replace(/[\^|$]/g, ''));
                            resolve(response[0]);
                        }

                    }).catch(error => {
                        setError(error);
                        setIsShow(true);
                        reject(error);
                }).finally(() => {
                    resolve({});
                });
            } else {
                resolve(data); // No action needed, resolve immediately.
            }
        });
    }


    function searchByDoc(func: (collection: string, doc: any) => Promise<any[]>
        , collection:string, doc:any, onlyIDs: boolean, titleKey: string, triggerEvent: boolean = true){

        let toSearch = doc;

        if (onlyIDs){
            toSearch = getOnlyDatabaseIDS(doc);
            delete toSearch._oldRev;
            delete toSearch._rev;
        }

        setIsShow(false);
        func(collection, toSearch)
            .then(response => {

                if (isNotUndefined(response) && response.length === 1) {
                    const _data = response[0]
                    setData(_data);
                    setIsShow(true);
                    setTitle(_data[titleKey]);
                    if (triggerEvent){
                        const inchikey = _data.INCHIKEY || _data.inchikey;
                        if (isNotUndefined(inchikey)) Events.trigger(EventsType.SEARCH_BY_INCHIKEY, inchikey);
                    }
                }
            }).catch(error => {
            setError(error);
            setIsShow(true);
        });

    }


    const height = useMemo(() => { return (isShow) ? 'auto' : '0px'; }, [isShow]);
    const margin  = useMemo(() => { return (isShow) ? '5px' : '0px';  },[isShow]);
    const visibility  = useMemo(() => { return (isShow) ? 'visible' : 'hidden';},[isShow]);

    const getDisplayWithActionButtons = () => showBasket ? 'block' : 'none';
    const getDisplayWithoutActionButtons = () => showBasket ? 'none' : 'block';

    const clearData = useCallback(()  => {
        setData([]);
        setIsShow(false);
    }, []);

    useEffect(() => {
        const _UUID = generateUUID();

        Events.on(EventsType.BUCKET_RELOAD_OBJECT, function(event: { detail: any }) {
            if (event.detail.HEADER === header){
                setTitle(event.detail.TITLE);
                setData(event.detail.DATA);
                setId(event.detail.id);
                setColumnFilters(event.detail.filter);
                setIsShow(true);
                setIsContentShow(true);
            }

        }, _UUID); // here we cannot decide what UUID was aligned

        Events.on(EventsType.CLEAR_ALL_DATA, function(){
            if (isUndefined(storageValue) && isActive)  clearData();
        }, _UUID);

        return () => {
            Events.off(EventsType.BUCKET_RELOAD_OBJECT, _UUID);
            Events.off(EventsType.CLEAR_ALL_DATA, _UUID);
        };
    },[storageValue, isActive]);


    return (
            <Card style={{
                margin: margin,
                width: width,
                height: height,
                visibility: visibility
            }}>
                <Card.Header>
                        <IconButton
                            size="small"
                            onClick={switchContentShowHide}

                        >
                            {isContentShow ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                       <span
                           style={{ cursor: 'pointer', minWidth: '50%' }}
                           onClick={switchContentShowHide}> {header}  <b> {title} </b>
                       </span>
                       <span style={{ display: getDisplayWithActionButtons() }}>
                             <IconButton title={"Unfreeze Card"}
                                         style={{ display: (!isActive ? 'inline' : 'none'), color: 'red' }}
                                         onClick={() => setIsActive(true)}
                             >
                                   <ToggleOnIcon style={{ fontSize: 'xx-large'}}/>
                               </IconButton>
                             <IconButton title={"Freeze Card"}
                                         style={{ display: (isActive ? 'inline' : 'none'), color: 'green'}}
                                         onClick={() => setIsActive(false)}
                             >
                                    <ToggleOffIcon style={{ fontSize: 'xx-large'}}/>
                               </IconButton>

                               <StorageIcon title={title}
                                            header={header}
                                            data={data}
                                            id={id}
                                            filter={columnFilters}/>
                               <IconButton
                                   onClick={clearData} color="primary"  >
                                    <Clear  />
                                </IconButton>

                       </span>

                        <span style={{ display: getDisplayWithoutActionButtons() }}>
                            <div style={{ width: '100px'}}>&nbsp;</div>
                        </span>


                </Card.Header>

                <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>
                <Card.Body hidden={!isContentShow}>
                    <Card.Text >
                        <CardComponent
                                       isActive={isActive}
                                       setIsShow={setIsShow}
                                       setTitle={setTitle}
                                       setSubtitle={setSubtitle}
                                       setData={setData}
                                       data={data}
                                       setError={setError}

                                       // textSearch={textSearch}
                                       // setTextSearch={setTextSearch}

                                       storageValue={storageValue}

                                       searchListBy={searchListBy}
                                       searchListByFilters={searchListByFilters}
                                       searchBy={searchBy}
                                       searchByDoc={searchByDoc}

                                       columnFilters={columnFilters}
                                       setColumnFilters={setColumnFilters}
                        />
                    </Card.Text>
                    <Alert  hidden={isEmpty(error)} severity="error">{error}</Alert>
                </Card.Body>

            </Card>

        );
};





