import React, {useEffect} from 'react';

import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

import Events from '../../../events';
import { EventsType } from '../../../events-types';

import {getConcentrationsByStrain, save, deleteDoc, STRAINS_COMP_CONC} from '../../../api/Strains';
import {iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";

import {CardWithBaseComponent} from "../../common/BaseCard";
import {isNotEmpty, isNotUndefined} from "../../../utils/string";
import {returnCompoundName} from "../../../utils/pair_entry";
import { ChartPie } from '../../graphics/ChartPie';
import FormEditModal from "../../common/FormEditModal";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import {ConcCompoundsByStrains} from "./types";

const _StrainsCompoundsConcentrations: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                         setData, data ,
                                                         setTitle, setSubtitle, isActive
                                                        }) => {
     const UUID = '_StrainsCompoundsConcentrations';

    // MODAL SAVE

    const saveData = async (_data: any) => {
        save(STRAINS_COMP_CONC, _data).then((res) => {
            // setData(res);
            setData(updateArray(data, res).sortBy('VALUE', 'desc'));
            setIsShow(true);
        }).catch(setError);
    }


    const deleteData = async (data: any) => {
        deleteDoc(STRAINS_COMP_CONC, data).then((res) => {
            setData({});
            setIsShow(false);
        }).catch(setError);
    }

    const undo = async (undoData:any) => {
        Object.assign(data, undoData);
        save(STRAINS_COMP_CONC, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const createEvent = (event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    };

    // OTHER FUNCS -------------------

    const tranformDataToPie = (data:any[]) => {
        return data.map(({ CONCENTRATION, ...rest }) => ({ VALUE: CONCENTRATION, ...rest }))
                    .sortBy('VALUE', 'desc');
    }

    const updateArray = (dataArray: any[], updatedObject: any) => {
        const indexToUpdate = dataArray.findIndex(item => item._key === updatedObject._key);

        if (indexToUpdate !== -1) {
            dataArray[indexToUpdate] = updatedObject;
        } else {
            dataArray.push(updatedObject);
        }

        return dataArray;
    };


    // SEARCH ----------------------

    const searchByStrainEvent = (event: { detail: any }) => {

        if (!isActive) return;

        setIsShow(false);

        const strain_id = event.detail.STRAIN_ID;

        const fixNumber = (d:any) => {
            if (isNotUndefined(d) && !isNaN(d)){
                return Number(d).toFixed(4);
            }
            else return 'N/A';
        }

        const tranformData = (data:any[], strain_id: number) => {
            return data.map((item) => ({
                CONCENTRATION: fixNumber(item.CONCENTRATION),
                NAME:  item.NAME || item.SYNONYMS || item.INCHIKEY,
                INCHIKEY: item.INCHIKEY,
                STRAIN_ID: strain_id,
                _id: item._id,
                _key: item._key,
                _rev: item._rev
            })).sortBy('CONCENTRATION', 'desc');
        }

        getConcentrationsByStrain(strain_id)
            .then((response) => {
                if (isNotUndefined(response) && response.length > 1) {
                    setTitle(strain_id);
                    setData(tranformData(response, strain_id));
                    setIsShow(true);
                }
            }).catch(error => {
            setError(error);
            setIsShow(true);
        });

    }

    // EVENTS -----------------------

    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        Events.on(EventsType.SEARCH_BY_STRAIN, searchByStrainEvent, UUID);

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH_BY_STRAIN, UUID);
        };
    }, [isActive]);

     // RENDER -----------------------------

    return (
        <>
            <ChartPie data={tranformDataToPie(data)} />

            <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Comp.Name</TableCell>
                        <TableCell align="center" > <b>(% dry weight)</b></TableCell>
                        <TableCell align="right" sx={{ minWidth: '150px' }}>Actions</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row: any) => (
                        <TableRow
                            key={row.INCHIKEY}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {returnCompoundName(row)}
                            </TableCell>
                            <TableCell align="right">{row.CONCENTRATION}</TableCell>
                            <TableCell align={"right"}>

                                <FormEditModal

                                    DATATYPE={STRAINS_COMP_CONC}
                                    save={saveData}
                                    data={row}
                                    schema={ConcCompoundsByStrains}/>
                               <AuditModal id={row._id} undo={undo}/>
                               <DisplayDeleteModal DATATYPE={STRAINS_COMP_CONC}
                                                            deleteDoc={deleteData}
                                                            data={data}
                                                            schema={ConcCompoundsByStrains}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>


        </>
    );
};


const StrainsCompoundsConcentrations: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_StrainsCompoundsConcentrations} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(StrainsCompoundsConcentrations);
