import React, {useEffect} from 'react';

import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import {FileDownload as FileDownloadIcon} from "@mui/icons-material";

import MaterialReactTable from 'material-react-table';

import Events from '../../../events';
import { EventsType } from '../../../events-types';

import {STRAINS, getStrainsRegEx, getFullTable, getByFilters} from '../../../api/Strains';
import {iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";

import {CardWithBaseComponent} from "../../common/BaseCard";
import {getColumns, getInitialColumnState} from "../../../utils/tables";
import {OnChangeFn} from "@tanstack/table-core/src/types";
import {ColumnFiltersState} from "@tanstack/table-core/src/features/Filters";
import {exportToExcel} from "../../../utils/export_xlsx";
import {isValidIchiKey} from "../../../utils/string";
import {Strain as StrainObject} from "./types";

const _StrainsTable: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                         setData, data, isActive,
                                                         setColumnFilters, columnFilters,
                                                         setTitle, setSubtitle,searchListBy, searchListByFilters,
                                                         }) => {
    const UUID = '_StrainsTable';

    const columnNames = Object.keys(StrainObject);
    const columnsToView = ['VARIETY_NAME', 'COUNTRY','COUNTRY_REGION',
        'TOTAL_THC','TOTAL_CBD','TOTAL_CBG','TOTAL_CBC'];

    const columns = getColumns(StrainObject);
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);

    async function _exportToExcel(data: any[]) {
        const strainIds = data.map(obj => new String(obj.STRAIN_ID || obj.original.STRAIN_ID).toString());
        const full_data = await getFullTable(strainIds);
        exportToExcel(full_data, 'Varieties with Concentrations');
    }

    // SEARCH -----------------

    // const searchByInchiKey = (text: string) => {
    //
    //     setIsShow(false);
    //     getByInchiKeys([text])
    //         .then(response => {
    //             if (isNotUndefined(response) && response.length > 0) {
    //                 setData(response);
    //                 setIsShow(true);
    //
    //                 getObjectsByRegEx(COMPOUND, 'INCHIKEY',  '^' + text + '$')
    //                     .then( r => {
    //                         setTitle(r[0].COMPOUND_NAME);
    //                     });
    //
    //             }
    //         }).catch(ex => {
    //         const error =
    //             ex.response.status === 404
    //                 ? "Resource Not found"
    //                 : "An unexpected error has occurred";
    //         setError(error);
    //     });
    // }

    function _search(text: string) {
        if (text.length > 0){
            if (isValidIchiKey(text)) {
               // searchByInchiKey(text );
            } else {
                searchListBy?.(getStrainsRegEx,STRAINS, 'VARIETY_NAME', text)
            }
        }
    }

    const searchEvent = (event: { detail: string }) => {
        if (isActive){
            _search(event.detail)
        }
    }

    const searchWizard = async(event: { detail: any }) => {
        if (isActive && event.detail.component === STRAINS){
            searchListByFilters?.(getByFilters, STRAINS, event.detail.filters, 'wizard result');
        }
    }

    // EVENTS ------------------

    useEffect(() => {
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive]);

    // RENDER -----------------

    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };


    return (
                <MaterialReactTable
                                columns={columns}
                                data={data?? []}
                                enableColumnFilterModes
                                enableRowSelection //enable some features
                                enableColumnOrdering
                                enableGlobalFilter={false} //turn off a feature
                                enableDensityToggle={true}
                                initialState={{
                                    columnVisibility: _initial_columns_state,
                                    density: 'compact',
                                }}
                    /* SAVE FILTERS   */

                                onColumnFiltersChange={handleFilterFnChange}
                                state={{ columnFilters }}

                    /* ACTIONS */

                                enableRowActions={true}
                                renderRowActions={({ row }) => (

                                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>

                                        <Button variant="contained" size="small"
                                                color="secondary"
                                                onClick={() => {
                                                    Events.trigger(EventsType.SEARCH_BY_STRAIN, row.original);
                                                }}>
                                            ID {row.original.STRAIN_ID}
                                        </Button>
                                    </Box>

                                )}
                        /*  DOWNLOAD */
                                positionToolbarAlertBanner="bottom"
                                renderTopToolbarCustomActions={({ table }) => (
                                    <Box
                                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                    >
                                        <Button
                                            color="primary"
                                            onClick={() => _exportToExcel(data)}
                                            startIcon={<FileDownloadIcon />}
                                            variant="contained"
                                        >
                                            Export All Data
                                        </Button>

                                        <Button
                                            disabled={
                                                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                            }
                                            onClick={() => {
                                                let _data = table.getSelectedRowModel().rows.map((row) => row.original);
                                                _exportToExcel(_data)}
                                            }
                                            startIcon={<FileDownloadIcon />}
                                            variant="contained"
                                        >
                                            Export Selected Rows
                                        </Button>
                                    </Box>
                                )}
                            />

    );
};


const StrainsTable: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width , eventGroupToTrigger}) => {
    return <CardWithBaseComponent cardComponent={_StrainsTable} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(StrainsTable);
