import React, {useEffect} from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";

import MaterialReactTable from "material-react-table";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import {FileDownload as FileDownloadIcon} from "@mui/icons-material";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {getColumns, getInitialColumnState} from "../../../utils/tables";
import {isNotUndefined, removeHtmlTags} from "../../../utils/string";
import { getListClinicalTrials} from "../../../api/ClinicalTrials";
import {OnChangeFn} from "@tanstack/table-core/src/types";
import {ColumnFiltersState} from "@tanstack/table-core/src/features/Filters";
import {exportToExcel} from "../../../utils/export_xlsx";
import {ClinicalTrial, iClinicalTrial} from "./types";

const _ClinicalTrialsTableCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data ,
                                                                    setColumnFilters, columnFilters, isActive,
                                                                    // setTextSearch, textSearch,
                                                                    setTitle
                                                              }) => {

    const UUID = '_ClinicalTrialsTableCard';
    const columnNames = Object.keys(ClinicalTrial);
    const columnsToView = ['NCT_ID','TITLE','STATUS', 'MAIN_SPONSOR'];

    const columns = getColumns(ClinicalTrial);
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);

    // SEARCH -------

    const getClinicalTrials = async (term: string) => {
        setIsShow(false);
        try {
            setTitle(term);
            const response = await getListClinicalTrials(term);
            const _list: iClinicalTrial[] = response.hits.map((obj: any) => {
                return {
                    NCT_ID: obj.id,
                    TITLE: removeHtmlTags(obj.study.protocolSection.identificationModule.briefTitle),
                    STATUS: obj.study.protocolSection.statusModule.overallStatus,
                    MAIN_SPONSOR: obj.study.protocolSection.sponsorCollaboratorsModule.leadSponsor.name
                };
            });

            if (isNotUndefined(_list) && _list.length > 0) {
                setData(_list);
                setIsShow(true);
            }
        } catch (error: any) {
            setError(error);
            setIsShow(true);
        }
    };

    function _search(text: string) {
        getClinicalTrials(text);
    }

    const searchEvent = (event: { detail: string }) => {
        if (isActive){
            // setTextSearch(event.detail);
            _search(event.detail);
        }
    }

    // const reloadEvent = (event: { detail: any }) => {
    //     setTimeout(() => { _search(textSearch); }, 500);
    // }

    // EVENTS ------

    useEffect(() => {
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_CAS, searchEvent, UUID);
       //Events.on(EventsType.RELOAD, reloadEvent, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_CAS, UUID);
          //  Events.off(EventsType.RELOAD, UUID);
        };
    }, [isActive]);


    // RENDER --------

    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            initialState={{
                density: 'compact',
                columnVisibility: _initial_columns_state,
                columnOrder: [
                    'mrt-row-actions',
                    'mrt-row-select',
                    'mrt-row-expand',
                    'NCT_ID',
                    'TITLE',
                    'STATUS',
                    'MAIN_SPONSOR'
                ],
            }}
            data={data?? []}
            enableRowSelection //enable some features
            enableColumnOrdering
            enableGlobalFilter={false} //turn off a feature
            enableDensityToggle={true}


            /* SAVE FILTERS   */

            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}

            /* ACTIONS */
            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <Button variant="contained" size="small"
                            color="secondary"
                            onClick={() => {
                                Events.trigger(EventsType.SEARCH_NCT_ID, row.original.NCT_ID);
                            }}>
                        {row.original.NCT_ID}
                    </Button>

                </Box>
            )}

            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "Clinical Trials")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => exportToExcel(table.getSelectedRowModel().rows, "Clinical Trials")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}

        />
    );
};


const ClinicalTrialsTableCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_ClinicalTrialsTableCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(ClinicalTrialsTableCard);

