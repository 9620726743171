import React, {useEffect} from "react";
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import Events from "../../../events";
import {EventsType} from "../../../events-types";

import {getDataByField} from "../../../api/Pubchem"
import {getColumns, getInitialColumnState} from "../../../utils/tables";
import MaterialReactTable from "material-react-table";
import {Box, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {FileDownload as FileDownloadIcon} from "@mui/icons-material";
import Card from "react-bootstrap/Card";
import {OnChangeFn} from "@tanstack/table-core/src/types";
import {ColumnFiltersState} from "@tanstack/table-core/src/features/Filters";
import {isNotUndefined} from "../../../utils/string";
import {exportToExcel} from "../../../utils/export_xlsx";
import {PBClinicalTrials} from "./types";

const _PubchemClinicalTrialsTableCard: React.FC<iBaseInnerCardProps> = ({  setIsShow, setError, setData, data ,
                                                                           setColumnFilters, columnFilters,
                                                                           // setTextSearch, textSearch,
                                                                           setTitle, isActive,
                                                                           }) => {

    const UUID = '_PubchemClinicalTrialsTableCard'
    const columnNames = Object.keys(PBClinicalTrials);
    const columnsToView = ['ctid','title','interventions', 'phase', 'status', 'diseaseids'];

    const columns = getColumns(PBClinicalTrials);
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);

    async function searchBy(key: string, value: string, field: string) {
        setIsShow(false);
        try {
            const response = await getDataByField(key, value, field);
            if (isNotUndefined(response) && isNotUndefined(response.data) && response.data.length > 0) {
                setTitle(value);
                setData(response.data);
                setIsShow(true);
            } else if (response.error) {
                setError(response.error);
                setIsShow(true);
            }
        } catch (error:any) {
            setError(error);
            setIsShow(true);
        }
    }

    function _search(text: string) {
        searchBy('clinicaltrials',text, '*');
    }

    const searchEvent = (event: { detail: string }) => {
        if (isActive){
            // setTextSearch(event.detail);
            _search(event.detail);
        }

    }

    const searchByCompoundEvent = (event: { detail: any }) => {
        if(isActive){
            const name = event.detail.COMPOUND_NAME;
            if (isNotUndefined(name) && name.indexOf("/") === -1) { // to avoid search from Audit trail
                //setTextSearch(name);
                searchBy('clinicaltrials', name, '*');
            }
        }
    }

    // const reloadEvent = (event: { detail: any }) => {
    //     setTimeout(() => { _search(textSearch); }, 500);
    // }

    // EVENTS ------

    useEffect(() => {
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_COMPOUND, searchByCompoundEvent, UUID);
      //  Events.on(EventsType.RELOAD, reloadEvent, UUID);
        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_COMPOUND, UUID);
          //  Events.off(EventsType.RELOAD, UUID);
        };
    }, [isActive]);

    // RENDER ------


    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            initialState={{
                density: 'compact',
                columnVisibility: _initial_columns_state,
                columnOrder: [
                    'mrt-row-actions',
                    'mrt-row-select',
                    'mrt-row-expand',
                    'ctid','title','interventions', 'phase', 'status', 'diseaseids'
                ],
            }}
            data={data?? []}
            enableRowSelection //enable some features
            enableColumnOrdering
            enableGlobalFilter={false} //turn off a feature
            enableDensityToggle={true}

            /* SAVE FILTERS   */

            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}

            /* EXPAND */

            renderDetailPanel={({ row }) => (
                <Box
                    sx={{
                        display: 'grid',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Typography><b>Conditions: </b> {row.original.conditions}</Typography>
                    <Typography><b>Update date: </b>{row.original.updatedate}</Typography>
                    <Typography><b>Link: </b> <Card.Link target="_blank" href={row.original.link}>
                        {row.original.link}
                    </Card.Link></Typography>
                </Box>
            )}

            /* ACTIONS */

            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <Button variant="contained" size="small"
                            color="secondary"
                            onClick={() => {
                                Events.trigger(EventsType.SEARCH_NCT_ID, row.original.ctid);
                            }}>
                        {row.original.ctid}
                    </Button>

                </Box>
            )}

            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "Pubchem Clinical Trials")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => exportToExcel(table.getSelectedRowModel().rows, "Pubchem Clinical Trials")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}

        />
    );
};



const PubchemClinicalTrialsTableCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PubchemClinicalTrialsTableCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(PubchemClinicalTrialsTableCard);


