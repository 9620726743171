import React  from 'react';
import {isNotEmpty, isUndefined} from '../../utils/string';
import { Clear } from '@mui/icons-material';
import {Button, IconButton} from '@mui/material';
import { useModal } from './Modal';
import { getAudit } from '../../api/Audits';
import "./../../css/Tables.css";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import {iAuditData} from "../tabs/loggers_audits/types";



interface AuditModalProps {
    id: string;
    undo: (doc: any) => any;
}

const AuditModal: React.FC<AuditModalProps> = ({ id, undo }) => {
    const { isOpen, modalData, handleOpenModal, handleCloseModal } = useModal();

    async function fetchData() {
        try {
            const data = await getAudit(id);
            handleOpenModal(data);
        } catch (error) {
            console.error('Error fetching audit data:', error);
        }
    }

    async function _undo(partialData: any) {
        undo(partialData);
        handleCloseModal();
    }


    if (!isOpen) {
        return <>
            <IconButton
                title={"Show Audit"}
                onClick={() => fetchData()}>
                <ReceiptLongIcon/>
            </IconButton>
        </>;
    }

    return (
        <div className={`modal ${isOpen ? 'visible' : ''}`}>
            <div className="modal-overlay">
                <div className="modal">
                    <div className="card-audit">

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h4>ID: {modalData[0]?.id || isUndefined(modalData[0]) && (
                                <b>No Audit created </b>
                                )}</h4>
                            <IconButton onClick={handleCloseModal} color="primary">
                                <Clear />
                            </IconButton>
                        </div>


                        {isNotEmpty(modalData) && (
                                <table className="audit-table">
                                    <thead>
                                    <tr>
                                        <th>User</th>
                                        <th>Date</th>
                                        <th>Before</th>
                                        <th>After</th>
                                        {/*<th>Reason</th>*/}
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {modalData.map((item: iAuditData, index: number) => (
                                        <tr key={index}>
                                            <td>{item.user}</td>
                                            <td className="small-column">
                                                {new Intl.DateTimeFormat(undefined, {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric'
                                                }).format(new Date(item.date))}
                                            </td>
                                            <td>
                                                {Object.keys(item.difference.before).map((key) => (
                                                    <div key={key}>
                                                        <b>{key}</b>: {item.difference.before[key]}
                                                    </div>
                                                ))}
                                            </td>
                                            <td>
                                                {Object.keys(item.difference.after).map((key) => (
                                                    <div key={key}>
                                                        <b>{key}</b>: {item.difference.after[key]}
                                                    </div>
                                                ))}
                                            </td>
                                            {/*<td>{item.reason}</td>*/}
                                            <td>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        _undo(item.difference.before);
                                                    }}
                                                >
                                                    RESTORE DATA
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuditModal;
