
export const isAnObject = (data: any) => {
    return (typeof data == 'object');
}

export const isNotNull = (data: any) => {
    return data != null && isNotEmpty(data);
}

export const isEmpty = (data: any) =>{
    return !isNotEmpty(data);
}

export const isNotEmpty = (data: any) => {
    return (isNotUndefined(data)
        && (
            (isAnObject(data) && Object.keys(data).length !== 0)
            || (Array.isArray(data) && data.length > 0)
            || (!isAnObject(data) && (('' + data).length > 0))
        )
    );
}

export const isNotUndefined = (data: any) =>{
    return (typeof data != 'undefined') && (data != null);
}

export const  isUndefined = (data: any) => {
    return (typeof data == 'undefined');
}

export function decodeAscii(asciiString: string) {
    if (!asciiString) {
        return ''; // Return an empty string for null or empty input
    }

    return asciiString.split('').map(char => {
        const code = parseInt(char);
        if (isNaN(code) || code < 0 || code > 127) {
            console.warn(`Invalid ASCII code: ${char}`);
            return '';
        }
        return String.fromCharCode(code);
    }).join('');
}

export const removeHtmlTags = (html: string): string => {
    const regex = /<[^>]+>/g;
    return html.replace(regex, '');
};

export const generateUUID = (prefix?: string): string => {
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string) => {
        const r: number = Math.random() * 16 | 0;
        const v: number = (c === 'x') ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });

    return prefix ? `${prefix}${uuid}` : uuid;
}


export const isValidCASNumber = (input: string): boolean => {
    const casNumbers = input.split(',').map(number => number.trim());
    const casNumberPattern = /^\d{2,7}-\d{2}-\d$/;
    return casNumbers.every(number => casNumberPattern.test(number));
}

export const isValidIchiKey = (input: string): boolean => {
    const inchikeys = input.split(',').map(key => key.trim());
    const inchikeyPattern = /^[A-Za-z]{14}-[A-Za-z]{10}-[A-Za-z]$/;
    return inchikeys.every(key => inchikeyPattern.test(key));
};

export const getOnlyDatabaseIDS = (obj: Record<string, any>): Record<string, any> => {
    return Object.keys(obj)
        .filter(key => key.startsWith('_'))
        .reduce((filteredObj: any, key) => {
            filteredObj[key] = obj[key];
            return filteredObj;
        }, {});
}

export function formatNumberWithDecimalsAndSeparation(
    num: number,
    decimalPlaces: number,
): string {
    const formattedNumber = num.toFixed(decimalPlaces);
    const parts = formattedNumber.split('.');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
}



