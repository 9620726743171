export interface iDifference {
    before: { [key: string]: any };
    after: { [key: string]: any };
}

export interface iAuditData {
    id: string;
    user: string;
    date: string;
    difference: iDifference;
    reason: string | null;
}

export interface iTableAuditData {
    id: string;
    user: string;
    date: string;
    diff_before: Record<string, any>;
    diff_after: Record<string, any>;
    reason: string | null;
}

export const TableAuditData: iTableAuditData = {
    id: '',
    user: '',
    date: '',
    diff_before: {},
    diff_after: {},
    reason: null
}
