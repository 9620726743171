import React, {useEffect} from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";


import MaterialReactTable from "material-react-table";
import {Box, IconButton, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {FileDownload as FileDownloadIcon, Visibility as VisibilityIcon} from "@mui/icons-material";
import {isNotUndefined, isValidIchiKey} from "../../../utils/string";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {CellEventMapping, createCellsWithEvents, getColumns, getInitialColumnState} from "../../../utils/tables";
import {OnChangeFn} from "@tanstack/table-core/src/types";
import {ColumnFiltersState} from "@tanstack/table-core/src/features/Filters";
import {exportToExcel} from "../../../utils/export_xlsx";
import {Stability} from "./types";
import {STABILITY, getObjectsByRegEx, getByFilters} from "../../../api/Compounds";

const _StabilityTableCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data ,
                                                              setColumnFilters, columnFilters, isActive,
                                                                searchListByFilters,
                                                                setTitle, setSubtitle,searchListBy
                                                          }) => {

    const UUID = '_StabilityTableCard';
    const columnNames = Object.keys(Stability);
    const columnsToView = ['STUDY_TITLE','COMPOUND_NAME','TREATMENT', 'ANALYSIS_TYPE', 'MEASURE_TYPE'];

    const eventMapping: CellEventMapping = {
        'INCHIKEY': EventsType.SEARCH_BY_INCHIKEY
    };

    const columns = getColumns(Stability, 0, createCellsWithEvents(eventMapping));
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);



    // SEARCH -----

    function _search(text: string) {
        if (text.length > 0) {
            if (isValidIchiKey(text)) {
                searchListBy?.(getObjectsByRegEx, STABILITY, 'INCHIKEY',text);
            } else {
                searchListBy?.(getObjectsByRegEx, STABILITY,
                    'STUDY_TITLE,TREATMENT,ANALYSIS_TYPE,MEASURE_TYPE,SAMPLING_DESCRIPTION,METHOD_DESCRIPTION', text);
            }
        }
    }

    const searchEvent = (event: { detail: string }) => {
        if(isActive){
            _search(event.detail);
        }

    }

    const searchWizard = async(event: { detail: any }) => {
        if (isActive && event.detail.component === STABILITY){
            searchListByFilters?.(getByFilters, STABILITY, event.detail.filters, 'wizard result');
        }
    }

    // EVENTS ------

    useEffect(() => {
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_INCHIKEY, searchEvent, UUID);
        Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive]);


    // RENDER --------


    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            initialState={{
                density: 'compact',
                columnVisibility: _initial_columns_state,
                columnOrder: [
                    'mrt-row-actions',
                    'mrt-row-select',
                    'mrt-row-expand',
                    'STUDY_TITLE',
                    'COMPOUND_NAME',
                    'TREATMENT',
                    'ANALYSIS_TYPE',
                    'MEASURE_TYPE',
                    'DATE',
                    'VARIETY',
                    'VARIETY_BATCH',
                    'TIMEPOINT_LABEL',
                    'TIMEPOINT_DAYS',
                    'RELATION',
                    'VALUE',
                    'UNIT',
                    'STD_DEV',
                    'LOD',
                    'LOQ',
                    'N_BIO_REPLICATES',
                    'N_TECHNICAL_REPLICATES'
                ],
            }}
            data={data?? []}

            /* SAVE FILTERS   */

            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}

            enableRowSelection //enable some features
            enableColumnOrdering
            enableGlobalFilter={false} //turn off a feature
            enableDensityToggle={true}

            renderDetailPanel={({ row }) => (
                <Box
                    sx={{
                        display: 'grid',
                        margin: 'auto',
                        width: '100%',
                    }}
                >
                    <Typography><b>Sampling Description:</b> {row.original.SAMPLING_DESCRIPTION}</Typography>
                    <Typography><b>Analytical Method Description:</b> {row.original.METHOD_DESCRIPTION}</Typography>
                </Box>
            )}

            /* ACTIONS */
            enableRowActions={true}
            renderRowActions={({ row }) => (

                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>

                    <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                            Events.trigger(EventsType.SEARCH_BY_STABILITY, row.original);
                        }}>
                        <VisibilityIcon />
                    </IconButton>
                </Box>

            )}

            /* DOWNLOAD BUTTONS */

            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "Stability Data")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => exportToExcel(table.getSelectedRowModel().rows, "Stability Data")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}

        />
    );
};

const StabilityTableCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_StabilityTableCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(StabilityTableCard);
