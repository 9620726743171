import '../utils/arrays';
import DataApiCache from "./Cache";

import {iChemSpiderChemicalData} from "../components/tabs/compounds/types";

// https://www.chemspider.com/compound-data/_#CSID#_/compound.json
// https://www.chemspider.com/compound-data/_#CSID#_/properties.json
// https://www.chemspider.com/compound-data/_#CSID#_/_#CSID#_.mol

// autosuggest: https://www.chemspider.com/api/autosuggest?query=_#TEXT#_
// images are generated using: https://rdkit.org/docs/source/rdkit.Chem.rdmolfiles.html

// old:  const URL_CHEMSPIDER_ID = process.env.REACT_APP_PROXY_API_BASE_URL + "/chemspider/Chemical-Structure._#CSID#_.html";
const URL_CHEMSPIDER_ID = process.env.REACT_APP_PROXY_API_BASE_URL +  "/chemspider/compound-data/_#CSID#_/properties.json"
const URL_CHEMSPIDER_SEARCH = process.env.REACT_APP_PROXY_API_BASE_URL + "/chemspider/api/search?value=_#TEXT#_&orderBy=Datasources&orderDirection=descending";


const _api = new DataApiCache();


interface ExperimentalProperty {
    property: string;
    values: Array<{ value: string; source: string; link: string }>;
}

interface PredictedProperty {
    property: string;
    values: Array<{ property: string, value: string }>;
}


function parseCompound(data: any) {
    const exp_data = extractExperimentalProperties(data);
    const pred_data =  extractPredictedProperties(data);

    return {
        experimental: exp_data,
        predicted: pred_data
    };
}

function extractTableData(data: any): iChemSpiderChemicalData[] {
    return data.Records.map((row: any) => ({
        id: row.ChemSpiderId || '',
        name: row.Title || '',
        molecularFormula: row.MolecularFormula || '',
        molecularWeight: row.MolecularMass?.AverageMass || '',
    }));
}

function extractExperimentalProperties(data: any): any {
    return [
        {
            property: "Melting Point",
            values: data.MeltingPointProperties.map((mItem: any) => ({
                value: mItem.ExtractedLowValue || '' + mItem.ExtractedUnit || '',
                source: mItem.DatasourceName || '',
                link: mItem.ExternalUrl || '',
            })),
        },
        {
            property: "Solubility",
            values: data.SolubilityProperties.map((mItem: any) => ({
                value: mItem.ExtractedLowValue || '' + mItem.ExtractedUnit || '',
                source: mItem.DatasourceName || '',
                link: mItem.ExternalUrl || '',
            })),
        },
        {
            property: "Boiling Point",
            values: data.BoilingPointProperties.map((mItem: any) => ({
                value: mItem.ExtractedLowValue || '' + mItem.ExtractedUnit || '',
                source: mItem.DatasourceName || '',
                link: mItem.ExternalUrl || '',
            })),
        },
        {
            property: "Density",
            values: data.DensityProperties.map((mItem: any) => ({
                value: mItem.SubmittedValue || '',
                source: mItem.DatasourceName || '',
                link: mItem.ExternalUrl || '',
            })),
        },
        {
            property: "LogP ",
            values: data.LogPProperties.map((mItem: any) => ({
                value: mItem.SubmittedValue || '',
                source: mItem.DatasourceName || '',
                link: mItem.ExternalUrl || '',
            })),
        },
        {
            property: "Vapor Pressure",
            values: data.VaporPressureProperties.map((mItem: any) => ({
                value: mItem.SubmittedValue || '',
                source: mItem.DatasourceName || '',
                link: mItem.ExternalUrl || '',
            })),
        },
        {
            property: "Flash Point",
            values: data.FlashPointProperties.map((mItem: any) => ({
                value: mItem.ExtractedLowValue || '' + mItem.ExtractedUnit || '',
                source: mItem.DatasourceName || '',
                link: mItem.ExternalUrl || '',
            })),
        },
        {
            property: "Refraction Index",
            values: data.RefractionIndexProperties.map((mItem: any) => ({
                value: mItem.SubmittedValue || '',
                source: mItem.DatasourceName || '',
                link: mItem.ExternalUrl || '',
            })),
        },
        {
            property: "Bioactivity",
            values: data.BioactivityProperties.map((mItem: any) => ({
                value: mItem.SubmittedValue || '',
                source: mItem.DatasourceName || '',
                link: mItem.ExternalUrl || '',
            })),
        }
    ];
}

function extractPredictedProperties(_data: any): any {

    const data = _data.PredictedACDLabsProperties;

    return [
        { property: "Density", value: data.Density, error: data.DensityError },
        { property: "Boiling Point", value: data.BoilingPoint, error: data.BoilingPointError },
        { property: "Vapor Pressure", value: data.VapourPressure, error: data.VapourPressureError },
        { property: "Enthalpy of Vaporization", value: data.EnthalpyVaporization, error: data.EnthalpyVaporizationError },
        { property: "Flash Point", value: data.FlashPoint, error: data.FlashPointError },
        { property: "Index of Refraction", value: data.IndexRefraction, error: data.IndexRefractionError },
        { property: "Molar Refractivity", value: data.MolarRefractivity, error: data.MolarRefractivityError },
        { property: "H-Bond Acceptors", value: data.HAcceptors },
        { property: "H-Bond Donors", value: data.HDonors },
        { property: "Freely Rotating Bonds", value: data.FreelyRotatingBonds },
        { property: "Rule of Five Violations", value: data.RuleFiveViolations },
        { property: "ACD LogP", value: data.ACDLogP },
        { property: "ACD LogD (pH 1)", value: data.ACDLogD1 },
        { property: "ACD LogD (pH 2)", value: data.ACDLogD2 },
        { property: "ACD BCF (fish, 1 day)", value: data.ACDBCF1 },
        { property: "ACD BCF (fish, 4 days)", value: data.ACDBCF2 },
        { property: "ACD KOC (soil, 1 day)", value: data.ACDKOC1 },
        { property: "ACD KOC (soil, 4 days)", value: data.ACDKOC2 },
        { property: "Polar Surface Area", value: data.PolarSurfaceArea },
        { property: "Polarizability", value: data.Polarizability, error: data.PolarizabilityError },
        { property: "Surface Tension", value: data.SurfaceTension, error: data.SurfaceTensionError },
        { property: "Molar Volume", value: data.MolarVolume, error: data.MolarVolumeError }

    ];
}





export const getCompoundByCSID = async (csid: string) => {

    const address = URL_CHEMSPIDER_ID.replace('_#CSID#_', csid);

    let data:any = await _api.get(address);

    data = parseCompound(data);

    return data;
}

export const getCompounds = async (text: string) => {

    const address = URL_CHEMSPIDER_SEARCH.replace('_#TEXT#_', text);

    let data:any = await _api.get(address);
    data = extractTableData(data);

    return data;
}



