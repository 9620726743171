import {DataApiCache} from '../api/Cache'

const baseURL = process.env.REACT_APP_PMI_API_BASE_URL;

export const STRAINS = "Strains";
export const STRAINS_COMP_CONC = "StrainsCompoundConcentrations";

const _api = new DataApiCache();

export const getStrains = async (type: string, key: string, value: string) => {
    try{
        if (!type || !key || !value) {
            throw new Error('Invalid input parameters');
        }
        const address = `${baseURL}/strains/${type}/${key}/${value}`;
        const data = await _api.get(address);
        return data;
    }
    catch(ex:any){
        throw new Error(`Failed to get strains for ${type} - ${key} - ${value} `);
    }
};

export const getConcentrationsByStrain = async (value: string) => {
    try{

        const address = baseURL +"/strains/Concentrations/STRAIN_ID/" + value;
        const data = await _api.get(address);
        return data;
    }
    catch(ex:any){
        throw new Error(`... cannot get data on ${value}`);
    }
};

export const getStrainIdFromConcentration = async (value:string) => {
    try{

        const address = baseURL +"/strains/strain_id/by_concentration/";
        const data = await _api.post(address,  value );
        return data;
    }
    catch(ex:any){
        throw new Error(`... cannot get data from ${value}`);
    }
}

export const getStrainByDoc = async (doc: any) => {
    try{
        const address = `${baseURL}/strains/${STRAINS}/`;
        const data = await _api.post(address, doc);
        return data;
    }
    catch(ex:any){
        throw new Error(`Failed to get data: ${ex.message}`);
    }
};

export const getObjectByDoc = async (type: string, doc: any) => {
    return getStrainByDoc(doc);
};

export const getStrainsRegEx = async (type: string, key: string, value: string) => {
    try{
        const address = `${baseURL}/strains/${type}/regex`;
        const data = await _api.post(address, {
            field: key,
            pattern: value
        });
        return data;
    }
    catch(ex:any){
        throw new Error(`Failed to get strains for ${type} - ${key} - ${value} `);
    }
};


export const getStrainsTaxonomy = async (key: string, value: string) => {
    try{
        if (value.length > 2) {
            const address = `${baseURL}/strains/${STRAINS}/taxonomy/`;
            const data = await _api.post(address, {
                field: key,
                pattern: value,
                rfield: 'VARIETY_NAME'
            });
            return data;
        }
        else return [];
    }
    catch(ex:any){
        throw new Error(`Failed to get strains for ${key} - ${value} `);
    }
};

export const getFullTable = async (values: string[]) => {
    try{
        if (values.length >= 1) {
            const address = `${baseURL}/strains/fulltable/`;
            const data = await _api.post(address, {
                ids: values
            });
            return data;
        }
        else return [];
    }
    catch(ex:any){
        throw new Error(`Failed to get full table strains for ${values} `);
    }
};

export const getByInchiKeys = async (values: string[]) => {
    try{
        if (values.length > 0) {
            const address = `${baseURL}/strains/byinchikeys/`;
            const data = await _api.post(address, {
                inchikeys: values
            });
            return data;
        }
        else return [];
    }
    catch(ex:any){
        throw new Error(`Failed to get full table strains for ${values} `);
    }
};

export const getByFilters = async (type: string, filters: any[]) => {
    try{
            const address = `${baseURL}/strains/${type}/byfilters/`;
            const data = await _api.post(address, {
                filters: filters
            });
            return data;
    }
    catch(ex:any){
        throw new Error(`Failed to get data strains using filters:  ${filters} `);
    }
};


export const save = async (type: string, doc: any) => {
    try{
        const address = `${baseURL}/strains/${type}/save`;
        const data  = await _api.post(address, doc);
        _api.clearCache();
        return data;
    }
    catch(ex){
        throw new Error(`Failed to save compound data for ${type} `);
    }
};

export const deleteDoc = async (type: string, doc: any) => {
    try{
        const address = `${baseURL}/strains/${type}/delete/${doc._key}`;
        const data  = await _api.delete(address);
        _api.clearCache();
        return data;
    }
    catch(ex){
        throw new Error(`Failed to delete compound data for ${type} `);
    }
};

export const saveBulk = async (type: string, docs: any[]) => {
    try{
        const address = `${baseURL}/strains/save/bulk/concentrations`;
        const data  = await _api.save_post(address, docs);
        _api.clearCache();
        return data;
    }
    catch(ex){
        throw new Error(`Failed to save bulk data for ${type} `);
    }
};




