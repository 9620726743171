import React, {useMemo} from "react";
import {useModal} from "../../common/Modal";
import Button from "@mui/material/Button";
import {getByFilters} from "../../../api/Baskets";
import {getCurrentUserNameAndRoles} from "../../../services/Cognito";
import {Box, IconButton} from "@mui/material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {Clear} from "@mui/icons-material";
import MaterialReactTable, {MRT_ColumnDef} from "material-react-table";
import Events from "../../../events";
import {EventsType} from "../../../events-types";


const SharedBasketsModal: React.FC = () => {
    const {isOpen, modalData, handleOpenModal, handleCloseModal} = useModal();


    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'NAME',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
                enableEditing: false
            },
            {
                accessorKey: 'user',
                header: 'USER',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
                enableEditing: false
            },
            {
                accessorKey: 'info',
                header: 'INFO',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
                enableEditing: false
            },
        ],
        []
    );

    async function fetchData() {
        try {
            const user = (await getCurrentUserNameAndRoles())?.login || 'undefined';
            const data = await getByFilters([{property: 'user', value: user, operator: '!='},
                                                    {property: 'shared', value: true, operator: '=='}]);
            handleOpenModal(data);
        } catch (error) {
            console.error('Error fetching audit data:', error);
        }
    }


    if (!isOpen) {
        return <>
                  <Button  color="primary"
                                 variant="contained" onClick={fetchData}  >
                    Open Shared Storages</Button>
                </>;
            }

    return (
                <div className={`modal ${isOpen ? 'visible' : ''}`}>
                    <div className="modal-overlay">
                        <div className="modal">
                            <div className="card-audit">

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h4>Shared Baskets</h4>
                                    <IconButton onClick={handleCloseModal} color="primary">
                                        <Clear />
                                    </IconButton>
                                </div>

                                <MaterialReactTable
                                    columns={columns}
                                    data={modalData ?? []}
                                    enableColumnOrdering
                                    enableGlobalFilter={false} //turn off a feature
                                    enableDensityToggle={true}
                                    initialState={{
                                        density: 'compact',
                                    }}

                                    /*  ACTIONS  */
                                    enableRowActions={true}
                                    renderRowActions={({ row }) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => {
                                                    Events.trigger(EventsType.BUCKET_CLONE, row.original);
                                                }}
                                            >
                                                <AddShoppingCartIcon />
                                            </IconButton>
                                        </Box>
                                    )}

                                ></MaterialReactTable>
                            </div>
                        </div>
                    </div>
                </div>
        );
};

export default SharedBasketsModal;
